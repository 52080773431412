import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
import { Color } from "@material-ui/lab/Alert";
import React, { ChangeEvent } from "react";
import { elementsPerPage } from "../../../components/src/MainLayout.web";
export interface APIPayloadType {
    contentType?: string;
    method: string;
    endPoint: string;
    apiBody?: object;
    body?: object;
    token?: string;
    type?: string;
}

const titleRegex =  /^(?=.*[a-zA-Z])[a-zA-Z0-9~@#$^*()_+=[\]{}|\\,.?: -]{1,50}$/, 
    detailRegex = /^(?=.*[a-zA-Z])[a-zA-Z0-9~@#$^*()_+=[\]{}|\\,.?: -]{1,300}$/;

export interface ValidResponseType {
    message: string;
    data: object;
    scheduled: object;
}

interface ResponseErrors {
  errors: {
    token: string;
  }[];
}

export interface AdminCourseClassesResponseType {
    errors: [{ token: string }];
    data: Array<CourseClassesDataType>;
    meta: {
        course_class: string;
        total_course_class: number;
        page: string;
        message: string;
    };
}

export interface CourseClassesDataType {
    id: string;
    type: string;
    attributes: {
        title: string;
        details: string;
        class_type: string;
        type_url: string;
        duration: string;
        status: boolean;
        featured: boolean;
        created_at: string;
        updated_at: string;
        course_chapter: {
            id: number;
            course_id: number;
            name: string;
            details: string;
            status: boolean;
            no_of_classes: number | null;
            created_at: string;
            updated_at: string;
        };
        child_category: string;
        rating: number;
        is_download: boolean;
        video_thumbnail: string | null;
        video_upload: string | null;
        sequence_number: number;
    };
}

export interface InvalidResponseType {
    errors: Array<string>;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    adminCourseClassesData: AdminCourseClassesResponseType;
    isDrawer: boolean;
    setfullWidth: boolean;
    currentPageCount: number;
    pageCount: number;
    userToken: string;
    loader: boolean;
    openDeleteCourseClassesModal: boolean;
    deleteCourseClassesId: string | number;
    isAlert: boolean;
    alertMsg: string;
    alertType: Color;
    isUpdate: boolean;
    perPage: number;
    searchedCourseClassesText: string;
    isHeaderChecked: boolean;
    isChecked: boolean[];
    openAddInstituteModal: boolean;
    stateListData: [];
    stateId: string;
    instituteId: string | number;
    openAddCourseClassesModal: boolean;
    title: string;
    titleError: string;
    detail: string;
    detailError: string;
    duration: string;
    durationError: string;
    courseClassStatus: any;
    featured: any;
    courseChapterListData: {
        attributes: { name: string }; id: string,
    }[];
    courseChapterId: string;
    courseChapterIdError: string;
    selectedOption: 'url' | 'upload_video' | "";
    selectedOptionError: string;
    videoUrl: string;
    videoFile: any;
    videoFileThumbnail: string[];
    videoFileName: string[];
    videoFileSize: string | undefined;
    videoRef: React.RefObject<HTMLVideoElement>;
    canvasRef: React.RefObject<HTMLCanvasElement>;
    defaultFileName: string;
    videoUrlFileError: string;
    courseClassId: string;
    currentSortState: { order: string; field: string };
    columnSort: string;
    fileIsUploading: boolean;
    courseId: string;
    SequenceNumber: string | unknown;
    selectedSequenceNumberArray: string [];
    sequenceNumberArray: string [];
    sequenceNumberError: string;
    chapterPageNo: number;
    chapterPagePer: number;
    totalCourseChapter: number;
    editClassesSequenceNumber: string | unknown;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class AdminCourseClassesController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getAdminCourseClasseslistApiCallId: string = "";
    deleteCourseClassesApiCallId: string = "";
    getCourseChapterListApiCallId: string = "";
    postAddCourseClassesApiCallId: string = "";
    showCourseClassesApiCallId: string = "";
    editCourseClassesApiCallId: string = "";
    statusUpdateApicallID: string = "";
    getCourseClassesListApiCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.ReciveUserCredentials),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.NavigationIdMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            adminCourseClassesData: {
                data: [],
                meta: {
                    page: "",
                    total_course_class: 0,
                    message: "",
                    course_class: ""
                },
                errors: [{ token: "" }]
            },
            isDrawer: true,
            setfullWidth: true,
            currentPageCount: 1,
            pageCount: 0,
            userToken: "",
            loader: true,
            fileIsUploading: false,
            videoRef: React.createRef(),
            canvasRef: React.createRef(),
            openDeleteCourseClassesModal: false,
            deleteCourseClassesId: "",
            isAlert: false,
            alertMsg: "",
            alertType: "success",
            isUpdate: false,
            perPage: elementsPerPage,
            searchedCourseClassesText: "",
            isHeaderChecked: false,
            isChecked: [],
            openAddInstituteModal: false,
            stateListData: [],
            stateId: "",
            instituteId: "",
            openAddCourseClassesModal: false,
            title: "",
            titleError: "",
            detail: "",
            detailError: "",
            duration: "",
            durationError: "",
            courseClassStatus: true,
            featured: true,
            courseChapterListData: [],
            courseChapterId: "",
            courseChapterIdError: "",
            selectedOption: "",
            selectedOptionError: "",
            videoUrl: '',
            videoFile:[],
            videoFileName: [],
            videoFileThumbnail: [],
            videoFileSize: undefined,
            defaultFileName: "",
            videoUrlFileError: "",
            courseClassId: "",
            currentSortState: { order: "desc", field: "id_sort" },
            columnSort: "",
            courseId: "",
            SequenceNumber: "1",
            selectedSequenceNumberArray:[],
            sequenceNumberArray: [...Array(99)],
            sequenceNumberError: '',
            chapterPageNo: 1,
            chapterPagePer: elementsPerPage,
            totalCourseChapter: 0,
            editClassesSequenceNumber: ""
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (!responseJson.errors) {
                this.apiSuccessCallBacks(apiRequestCallId, responseJson);
            } else {
                this.parseErrors(responseJson);
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start
    parseErrors = async (responseJson: ResponseErrors) => {
        if (Object.keys(responseJson.errors[0])[0] === "token") {
            this.navigationLoginScreen();
        } else {
            this.parseApiErrorResponse(responseJson);
        }
    };

    async componentDidMount() {
        await this.getUserToken()
    }

    getUserToken = async () => {
        const token = await getStorageData('loginToken');
        this.setState({ userToken: token });
        await this.getCourseClassesDataFunction(this.state.currentPageCount, this.state.perPage, this.state.searchedCourseClassesText, this.state.columnSort);
        await this.getCourseChaptersDataFunction(this.state.chapterPageNo, this.state.chapterPagePer);
    };

    adminCourseClassesApiCall = (data: APIPayloadType) => {
        let { contentType, method, endPoint, body, type } = data;
        const header = {
            "Content-Type": contentType,
            "token": this.state.userToken
        };
        let requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );
        body &&
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                type === "" ? JSON.stringify(body) : body
            );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);

        return requestMessage.messageId;
    };

    isCourseClassesValidResponse = (responseJson: ValidResponseType) => {
        return (responseJson);
    };

    apiSuccessCallBacks = (apiRequestCallId: string, responseJson: AdminCourseClassesResponseType) => {
        if (apiRequestCallId === this.getAdminCourseClasseslistApiCallId) {
            this.handleGetCourseClassesListResponse(responseJson);
        }
        if (apiRequestCallId === this.postAddCourseClassesApiCallId) {
            this.handleAddCourseClassesResponse(responseJson);
        }
        if (apiRequestCallId === this.editCourseClassesApiCallId) {
            this.handleEditAdminCourseClassesListResponse(responseJson);
        }
        if (apiRequestCallId === this.showCourseClassesApiCallId) {
            this.handleShowAdminCourseClassesResponse(responseJson);
        }
        if (apiRequestCallId === this.statusUpdateApicallID) {
            this.handleAdminCourseClassStatusResponse(responseJson)
        }
        if (apiRequestCallId === this.deleteCourseClassesApiCallId) {
            this.handleDeleteCourseClassesListResponse(responseJson);
        }
        if (apiRequestCallId === this.getCourseClassesListApiCallId) {
            this.handleCourseChapterListResponse(responseJson);
        }
    };

    handleAdminCourseClassStatusResponse = async (responseJson: object) => {
        await this.getCourseClassesDataFunction(this.state.currentPageCount, this.state.perPage, this.state.searchedCourseClassesText, this.state.columnSort)
        this.setState({ isAlert: true, alertMsg: "Course class status is updated successfully." })
    };

    handleEditAdminCourseClassesListResponse = (responseJson: AdminCourseClassesResponseType) => {
        let alertMsg, alertType: any;
        if (responseJson.errors) {
            alertMsg = "URL must be a valid URL";
            alertType = "error";
        } else {
            alertMsg = "Course Class Edited Successfully";
            alertType = "success";
            this.handleFeildReset();
            this.getCourseClassesDataFunction(this.state.currentPageCount, this.state.perPage, this.state.searchedCourseClassesText, this.state.columnSort)
            this.handleCloseCourseClassModal();
        }
        this.setState({ isAlert: true, alertMsg, alertType, loader: false });
    };

    handleShowAdminCourseClassesResponse = (response: any) => {
        const { attributes } = response.data;
        const file = attributes.class_type === "upload_video" ? {
            videoFileName: [attributes.video_upload_name],
            defaultFileName: attributes.video_upload_name,
            videoFileThumbnail: [attributes.video_thumbnail],
        } : {
            videoUrl: attributes.type_url
        },
            valuesToSet: unknown = {
                ...file,
                isUpdate: true,
                loader: false,
                title: attributes.title,
                detail: attributes.details,
                duration: attributes.duration,
                courseClassStatus: attributes.status,
                featured: attributes.featured,
                courseChapterId: attributes.course_chapter.id,
                courseClassId: response.data.id,
                selectedOption: attributes.class_type,
                SequenceNumber: attributes.sequence_number,
                editClassesSequenceNumber: attributes.sequence_number
                
            };
        this.setState(valuesToSet as Pick<S, keyof S>, () => {
            this.handleOpenAddCourseClassModal();
        });
    };

    handleAddCourseClassesResponse = (response: any) => {
        const { errors } = response;
        let alertMsg, alertType: Color;
        if (errors && errors.length > 0 && errors[0].type_url) {
            alertMsg = "URL must be a valid URL";
            alertType = "error";
        } else {
            alertMsg = "Course Class Created Successfully";
            alertType = "success";
            this.handleFeildReset();
            this.getCourseClassesDataFunction(1, this.state.perPage, "", this.state.columnSort)
            this.handleCloseCourseClassModal();
            this.setState({
                selectedSequenceNumberArray: [...this.state.selectedSequenceNumberArray, this.state.SequenceNumber as string]
            });
        }
        this.setState({ isAlert: true, alertMsg, alertType });
    };

    handleCourseChapterListResponse = (response: any) => {
        this.setState({
            chapterPageNo: this.getResponseResult(response) ? this.state.chapterPageNo  : 1,
            courseChapterListData: this.getResponseResult(response) ? [...this.state.courseChapterListData, ...response.data] : this.state.courseChapterListData,
            loader: false,
            totalCourseChapter: response.meta.total_course_chapter
        });
    };

    getResponseResult(response:{data:[{attributes: { name: string }; id: string,}]}){
        if(response.data != undefined){
            return true
        }
        return false
    }

    handleDeleteCourseClassesListResponse = (response: any) => {
        this.setState({
            deleteCourseClassesId: "",
            openDeleteCourseClassesModal: false,
            isAlert: true,
            alertMsg: "Course Class Deleted Successfully",
            loader: false,
            alertType: "success"
        }, () => this.getCourseClassesDataFunction(this.state.currentPageCount, this.state.perPage, this.state.searchedCourseClassesText, this.state.columnSort));
    };

    handleGetCourseClassesListResponse = (response: AdminCourseClassesResponseType) => {
        const { meta } = response;
        if (meta?.course_class === configJSON.courseClassNotFoundMessage) {
            this.setState({
                isAlert: true,
                alertMsg: "Record not found.",
                alertType: "info",
                loader: false,
                pageCount: 0,
                adminCourseClassesData: {
                    data: [],
                    meta: {
                        page: "",
                        total_course_class: 0,
                        message: "",
                        course_class: ""
                    },
                    errors: [{ token: "" }]
                }
            });
        } else if (response && response?.errors) {
            if (response?.errors[0]?.token == configJSON.tokenExpiredMessage) {
                this.navigationLoginScreen()
            }
        } else {
            this.addAdminCourseSequenceNumber(response)
            const pageCount = Math.ceil(meta.total_course_class / this.state.perPage);
            this.setState({
                adminCourseClassesData: response,
                loader: false,
                pageCount: pageCount
            });
        }
    };

    addAdminCourseSequenceNumber(response: AdminCourseClassesResponseType){
        let newSequenceNumberArray : string[] = [];
        response.data.forEach(element => {
          newSequenceNumberArray.push(element.attributes.sequence_number.toString())
        });
        this.setState({
          selectedSequenceNumberArray: newSequenceNumberArray
        });
      }

    onCloseAlert = () => {
        this.setState({ isAlert: false });
    };

    handleCloseDeleteCourseClassesModal = () => {
        this.setState({
            openDeleteCourseClassesModal: false,
            deleteCourseClassesId: ""
        });
    };

    handleOpenDeleteCourseClassesModal = (id: number) => {
        this.setState({
            openDeleteCourseClassesModal: true,
            deleteCourseClassesId: id
        });
    };

    handleCloseCourseClassModal = () => {
        this.setState({
            openAddCourseClassesModal: false,
        }, () => this.handleFeildReset());
    };

    handleOpenAddCourseClassModal = () => {
        this.setState({
            openAddCourseClassesModal: true,
        });
    };

    handleTitle = (event: { target: { value: string } }) => {
        let errorMessage = ""
        if (!titleRegex.test(event.target.value)) {
            errorMessage = "Please enter a valid Title"
        }
        this.setState({
            title: event.target.value,
            titleError: errorMessage
        });
    };

    handleDetail = (event: { target: { value: string } }) => {
        let errorMessage = ""
        if (!titleRegex.test(event.target.value)) {
            errorMessage = "Please enter a valid Detail"
        }
        this.setState({
            detail: event.target.value,
            detailError: errorMessage
        });
    };

    handleDuration = (event: { target: { value: string } }) => {
        const inputValue = event.target.value;
        if (/^\d*$/.test(inputValue)) {
            this.setState({ duration: inputValue, durationError: "" });
        }
    };

    handleCourseClassStatusCheck = (event: { target: { checked: boolean } }) => {
        this.setState({
            courseClassStatus: event.target.checked
        });
    };

    handleFeaturedCheck = (event: { target: { checked: boolean } }) => {
        this.setState({
            featured: event.target.checked
        });
    };

    handleCourseChapterSelectChange = (id: any) => {
        let errorMessage = "";
        if (!id) errorMessage = "Please select a course chapter";
        this.setState({
            courseChapterId: id,
            courseChapterIdError: errorMessage
        });
    };

    handleSequenceSelectChange = (id: string | unknown) => {
        this.setState({
          SequenceNumber: id,
          sequenceNumberError: ""
        });
      };

    handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        this.setState({ selectedOption: event.target.value as 'url' | 'upload_video', selectedOptionError: "", videoUrlFileError: "", videoFile: [], videoUrl: ""});
    };

    handleUrlChange = (event: ChangeEvent<HTMLInputElement>) => {
        this.setState({ videoUrl: event.target.value, videoUrlFileError: "" });
    };

    handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        this.setState({ fileIsUploading: true });
    
        const files = event.target?.files;
        if (!files || files.length === 0) {
            return this.setState({
                videoUrlFileError: "No file selected",
                fileIsUploading: false
            });
        }
    
        const allowedFormats = [
            'video/mp4', 'video/webm', 'video/ogg', 'video/avi',
            'video/mov', 'video/mkv', 'video/wmv', 'video/flv'
        ];
        const maxFileSize = 50 * 1024 * 1024;
    
        const videoFile = this.state.isUpdate ? [] : [...this.state.videoFile];
        const videoFileName = this.state.isUpdate ? [] : [...this.state.videoFileName];
        const videoFileThumbnail = this.state.isUpdate ? [] : [...this.state.videoFileThumbnail];
        const videoFileErrors: string[] = [];
    
        const processFile = (file: File) => {
            if (!allowedFormats.includes(file.type)) {
                videoFileErrors.push(`Invalid file format. Please upload a video file.`);
            } else if (file.size > maxFileSize) {
                videoFileErrors.push(`File size exceeds 50MB. Please choose a smaller file.`);
            } else {
                const fileIndex = videoFileName.indexOf(file.name);
                if (fileIndex !== -1) {
                    videoFile[fileIndex] = file;
                    videoFileThumbnail[fileIndex] = '';
                    this.generateThumbnail(file, fileIndex);
                } else {
                    videoFile.push(file);
                    videoFileName.push(file.name);
                    videoFileThumbnail.push('');
                    this.generateThumbnail(file, videoFile.length - 1);
                }
            }
        };
    
        Array.from(files).forEach(processFile);
    
        if (videoFileErrors.length > 0) {
            this.setState({
                videoUrlFileError: videoFileErrors.join(' '),
                fileIsUploading: false
            });
        } else {
            this.setState({
                videoFile,
                videoFileName,
                videoUrlFileError: '',
                fileIsUploading: false,
                videoFileThumbnail
            });
        }
    };
    

    generateThumbnail = (videoFile: File, index: number) => {
        const videoElement = document.createElement('video');
        const canvasElement = document.createElement('canvas');
        videoElement.src = URL.createObjectURL(videoFile);

        videoElement.onloadeddata = () => {
        canvasElement.width = videoElement.videoWidth;
        canvasElement.height = videoElement.videoHeight;
        const context = canvasElement.getContext('2d');
        if (context) {
            context.drawImage(videoElement, 0, 0, canvasElement.width, canvasElement.height);
                const thumbnailDataUrl = canvasElement.toDataURL('image/jpeg');
            URL.revokeObjectURL(videoElement.src);

                this.setState(prevState => {
                    const videoFileThumbnail = [...prevState.videoFileThumbnail];
                    videoFileThumbnail[index] = thumbnailDataUrl;
                    return { videoFileThumbnail, fileIsUploading: false };
                });
            }
        };
    };
    
    handleRemoveFile = (index: number) => {
        this.setState(prevState => {
            const videoFile = [...prevState.videoFile];
            const videoFileName = [...prevState.videoFileName];
            const videoFileThumbnail = [...prevState.videoFileThumbnail];

            videoFile.splice(index, 1);
            videoFileName.splice(index, 1);
            videoFileThumbnail.splice(index, 1);

            return { videoFile, videoFileName, videoFileThumbnail };
        });
    };


    handleFeildReset = () => {
        this.setState({
            selectedOption: "url",
            videoUrl: "",
            videoFile: [],
            videoFileName: [],
            videoFileThumbnail: [],
            courseChapterId: "",
            titleError: "",
            detailError: "",
            durationError: "",
            videoUrlFileError: "",
            selectedOptionError: "",
            courseChapterIdError: "",
            featured: true,
            courseClassStatus: true,
            isUpdate: false,
            duration: "",
            detail: "",
            title: "",
            SequenceNumber: "1",
            sequenceNumberError: ""
        });
    };


    checkFormValidations(updatedValues: any): boolean {
        const validationFields = [
            {
                field: "courseChapterId",
                errorKey: "courseChapterIdError",
                errorMessage: "Course Chapter is Required",
            },
            {
                field: "title",
                errorKey: "titleError",
                errorMessage: "Title is Required",
                regex: titleRegex
            },
            {
                field: "detail",
                errorKey: "detailError",
                errorMessage: "Detail is Required",
                regex: detailRegex
            },
            {
                field: "duration",
                errorKey: "durationError",
                errorMessage: "Duration is Required"
            },
            {
                field: "selectedOption",
                errorKey: "selectedOptionError",
                errorMessage: "Type is Required"
            },
        ];
        if (this.state.selectedOption) {
            validationFields.push(this.state.selectedOption === "url" ? {
                field: "videoUrl",
                errorKey: "videoUrlFileError",
                errorMessage: "Video URL is Required"
            } : {
                field: "videoFileName",
                errorKey: "videoUrlFileError",
                errorMessage: "Video FIle is Required"
            });
        }
        if(!this.checkSequenceNumberValidate()){
            validationFields.push({
                field: "SequenceNumber",
                errorKey: "sequenceNumberError",
                errorMessage: "Sequence number already exist"
            });
        }

        let hasError = false;

        validationFields.forEach(({ field, errorKey, errorMessage, regex }) => {
            if (
                !updatedValues[field] ||
                updatedValues[field]?.length === 0 ||
                (regex && !regex.test(updatedValues[field]))
            ) {
                this.setState((prevState) => ({
                    ...prevState,
                    [errorKey]: errorMessage
                }));
                hasError = true;
            } else {
                this.setState((prevState) => ({
                    ...prevState,
                    [errorKey]: ""
                }));
            }
        });

        return hasError;
    }

    checkSequenceNumberValidate() {
        if(this.state.editClassesSequenceNumber == this.state.SequenceNumber){
            return true;
        }
        let arr1 = this.state.selectedSequenceNumberArray.find((element) => { return this.state.SequenceNumber == element });
        if (arr1 === undefined) {
            return true;
        }
        return false;
    }

    handleEditCourseClass = async () => {
        this.setState({ loader: true });
        const { courseClassId, selectedOption, videoUrl, videoFileName, courseChapterId, featured, courseClassStatus, duration, detail, title, SequenceNumber } = this.state;
        const formValues = { courseChapterId, title, detail, duration, videoUrl, videoFileName, selectedOption };
        if (!this.checkFormValidations(formValues)) {
            const formdata = new FormData();
            formdata.append("course_classes[title]", title);
            formdata.append("course_classes[course_chapter_id]", courseChapterId);
            formdata.append("course_classes[details]", detail);
            formdata.append("course_classes[status]", courseClassStatus);
            formdata.append("course_classes[duration]", duration);
            formdata.append("course_classes[class_type]", selectedOption);
            formdata.append("course_classes[featured]", featured);
            formdata.append("course_classes[sequence_number]", SequenceNumber as string);
            switch (selectedOption) {
                case 'url':
                    formdata.append("course_classes[type_url]", videoUrl);
                    break;
                case 'upload_video':
                        this.state.videoFile.forEach((file: File) => {
                            formdata.append("course_classes[video_upload][]", file);
                        });
                    break;
            }

            this.editCourseClassesApiCallId = this.adminCourseClassesApiCall({
                method: configJSON.httpPutType,
                endPoint: `${configJSON.getAllCourseClassesAPIEndPoint}/${courseClassId}`,
                body: formdata,
                type: "formdata"
            });
        } else {
            this.setState({ loader: false });
        }
    };

    handlePostCourseClasses = async () => {
        this.setState({ loader: true });
        const { selectedOption, videoUrl, videoFile, videoFileName, courseChapterId, featured, courseClassStatus, duration, detail, title, SequenceNumber } = this.state;
        const formValues = { courseChapterId, title, detail, duration,videoFile, videoUrl, videoFileName, selectedOption };
        
        if (!this.checkFormValidations(formValues)) {
            this.setState({ 
                    sequenceNumberError:"",
                    selectedSequenceNumberArray: [...this.state.selectedSequenceNumberArray, this.state.SequenceNumber as string]
             });
            const formdata = new FormData();
            formdata.append("course_classes[course_chapter_id]", courseChapterId);
            formdata.append("course_classes[title]", title);
            formdata.append("course_classes[details]", detail);
            formdata.append("course_classes[duration]", duration);
            formdata.append("course_classes[status]", courseClassStatus);
            formdata.append("course_classes[featured]", featured);
            formdata.append("course_classes[class_type]", selectedOption);
            formdata.append("course_classes[sequence_number]", SequenceNumber as string);
            if (selectedOption === 'url') {
                formdata.append("course_classes[type_url]", videoUrl);
            } else if (selectedOption === 'upload_video') {
                this.state.videoFile.forEach((file:any) => {
                    formdata.append("course_classes[video_upload][]", file);
                });
            }
            
            this.postAddCourseClassesApiCallId = this.adminCourseClassesApiCall({
                method: configJSON.httpPostType,
                endPoint: configJSON.getAllCourseClassesAPIEndPoint,
                body: formdata,
                type: "formdata"
            });
        } else {
            this.setState({ loader: false });
        }
    };

    handleShowCourseClasses = async (id: string | number) => {
        this.setState({ loader: true });
        const endPoint = `${configJSON.getAllCourseClassesAPIEndPoint}/${id}`;
        this.showCourseClassesApiCallId = this.adminCourseClassesApiCall({
            contentType: configJSON.courseClassesApiContentType,
            method: configJSON.httpGetType,
            endPoint
        });
    };

    handleGetCourseChapterlist = async () => {
        this.setState({ loader: true });
        const endPoint = "bx_block_profile/course_chapters/list_course_chapters";
        this.getCourseChapterListApiCallId = this.adminCourseClassesApiCall({
            contentType: configJSON.courseClassesApiContentType,
            method: configJSON.httpGetType,
            endPoint
        });
    };

    handleDeleteCourseClasses = async (id: string | number) => {
        this.setState({ loader: true });
        const endPoint = `${configJSON.getAllCourseClassesAPIEndPoint}/${id}`;
        this.deleteCourseClassesApiCallId = this.adminCourseClassesApiCall({
            contentType: configJSON.courseClassesApiContentType,
            method: configJSON.httpDeleteType,
            endPoint
        });
    };

    onCourseClassesPageChange = (event: any, value: any) => {
        const newPageCount = Number(value);
        this.setState({ currentPageCount: newPageCount }, () => {
            this.getCourseClassesDataFunction(newPageCount, this.state.perPage, this.state.searchedCourseClassesText, this.state.columnSort);
        });
    };

    handleCourseClassesSearch = (e: { target: { value: string } }) => {
        const searchedCourseClassesText = e.target.value;
        this.setState({ searchedCourseClassesText, currentPageCount: 1 }, () => {
            this.getCourseClassesDataFunction(this.state.currentPageCount, this.state.perPage, this.state.searchedCourseClassesText, this.state.columnSort);
        });
    };

    getCourseClassesDataFunction = async (pageNo: number, per_page: number, searchedInstituteString: string, sorting: string) => {
        this.setState({ loader: true });
        const courseId = await getStorageData('EditId');
        this.setState({ courseId: courseId });
        const endPoint = `${configJSON.getAllCourseClassesAPIEndPoint}/search?page=${pageNo}&per_page=${per_page}&search_params=${searchedInstituteString}${sorting || ""}&course_id=${this.state.courseId}`;
        this.getAdminCourseClasseslistApiCallId = this.adminCourseClassesApiCall({
            contentType: configJSON.courseClassesApiContentType,
            method: configJSON.httpGetType,
            endPoint
        });
    };


    handleSort(field: string) {
        const sortParams: Record<string, string> = {
            id: "id_sort",
            ["Course Chapters"]: "course_chapter_name_sort",
            ["Sequence Number"]: "sequence_number_sort",
            status: "status_sort"
        };
        let fieldSort = "";
        const sortOrder = this.state.currentSortState.order === "asc" ? "desc" : "asc";

        if (sortParams.hasOwnProperty(field)) {
            fieldSort = `&${sortParams[field]}=${sortOrder}`;
        }
        this.toggleSortState(field, fieldSort);
        this.getCourseClassesDataFunction(this.state.currentPageCount, this.state.perPage, this.state.searchedCourseClassesText, fieldSort);
    }

    toggleSortState = (field: string, columnSort: string) => {
        this.setState({
            columnSort,
            currentSortState: {
                field,
                order: this.state.currentSortState.order === "asc" ? "desc" : "asc"
            }
        });
    };

    handleCourseClassesHeaderCheckboxChange = () => {
        this.setState(prevState => {
            const isHeaderChecked = !prevState.isHeaderChecked;
            const isChecked = Array(prevState.adminCourseClassesData.data.length).fill(isHeaderChecked);

            return { isHeaderChecked, isChecked };
        });
    };

    handleCourseClassesRowCheckboxChange = (index: number) => {
        this.setState(prevState => {
            const isChecked = [...prevState.isChecked];
            isChecked[index] = !isChecked[index];
            const isHeaderChecked = isChecked.every(value => value);

            return { isChecked, isHeaderChecked };
        });
    };

    handleCourseclassStatus = async (courseClassId: string) => {
        const updatedNotifications = this.state.adminCourseClassesData.data.map(notification =>
            notification.id === courseClassId ? {
                ...notification,
                attributes: {
                    ...notification.attributes,
                    status: !notification.attributes.status
                }
            } : notification
        );
        this.setState({
            adminCourseClassesData: {
                data: updatedNotifications,
                errors: [{ token: "" }],
                meta: {
                    page: "",
                    message: "",
                    course_class: "",
                    total_course_class: 0
                }
            }
        });

        const clickedData: CourseClassesDataType | undefined = updatedNotifications.find(notification => notification.id === courseClassId);
        const Value: boolean | undefined = clickedData?.attributes.status;

        if (typeof Value === 'boolean' || Value === undefined) {
            const stringValue = Value ? 'true' : 'false';
            const formdata = new FormData();
            formdata.append("course_classes[status]", stringValue);

            this.statusUpdateApicallID = this.adminCourseClassesApiCall({
                method: configJSON.httpPutType,
                endPoint: `${configJSON.getAllCourseClassesAPIEndPoint}/${courseClassId}`,
                body: formdata,
                type: "formdata"
            });
        }
    };
    navigationLoginScreen = () => {
        alert(configJSON.sessionExpireMsg);
        const messageLogin: Message = new Message(
            getName(MessageEnum.NavigationEmailLogInMessage)
        );
        messageLogin.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(messageLogin);
    };

    handleSidebarItemClick = (route: string) => {
        const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage), route);
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(message);
      };

      getCourseChaptersDataFunction = async (pageNo: number, per_page: number) => {
        this.setState({ loader: true });
        const endPoint = `${configJSON.getCourseChaptersAPiEndPoint}/search?page=${pageNo}&per_page=${per_page}&search_params=${""}&course_id=${this.state.courseId}`;
        this.getCourseClassesListApiCallId = this.adminCourseClassesApiCall({
          contentType: configJSON.courseChaptersApiContentType,
          method: configJSON.httpGetType,
          endPoint
        });
      };

    handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
        const dropdown = event.target as HTMLDivElement;
        const isBottom = dropdown.scrollHeight === dropdown.scrollTop + dropdown.clientHeight;
        if (isBottom && this.state.totalCourseChapter != this.state.courseChapterListData.length) {
            const newChapterPageCount = this.state.chapterPageNo + 1;
            this.setState({ chapterPageNo: newChapterPageCount }, () => {
                this.getCourseChaptersDataFunction(newChapterPageCount, this.state.chapterPagePer);
            });
        }
    };
    // Customizable Area End
}