// Customizable Area Start
import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "framework/src/Utilities";
import { Color } from "@material-ui/lab/Alert";
import { elementsPerPage } from "../../../components/src/MainLayout.web";
export const configJSON = require("./config");

export interface APIPayload {
  contentType?: string;
  method: string;
  endPoint: string;
  apiBody?: object;
  body?: object;
  token?: string;
  type?: string;
}

export interface TestimonialsResponseList {
  data: TestimonialData[];
  meta: Meta;
  errors: any[];
  error: string
}

export interface TestimonialData {
  id: string;
  type: string;
  attributes: Attributes;
}

export interface Attributes {
  id: number;
  rating: number;
  comment: string;
  user_name: UserName;
  course_details: CourseDetails;
}

export interface CourseDetails {
  id: number;
  course_name: string;
}

export interface UserName {
  id: number;
  full_phone_number: string;
  email: string;
  full_name: string;
}

export interface Meta {
  message: string;
  page: string;
  total_testimonial: number;
}

type Item = {
    id: string;
};

// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isLoading: boolean;
  userToken: string;
  pageNo: number;
  perPage: number;
  totalPages: number;
  searchText: string;
  isAlert: boolean;
  alertMsg: string;
  alertType: Color;
  testimonialsList: TestimonialData[];
  isHeaderChecked: boolean;
  isChecked: boolean[];
  openAddReviewModal: boolean;
  openDeleteReviewModal: boolean;
  ratingListData: Item[];
  ratingId: string;
  courseId: string;
  courseName: string;
  rating: number;
  comment: string;
  isUpdate: boolean;
  loader: boolean;
  selectedReviewId: string;
  deleteReviewId: string | number;
  selectedRatingId : string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class CourseReviewsAndRatingsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getTestimonialsListApiCallId: string = "";
  addReviewApiCallId: string = "";
  deleteReviewsAndRatingsApiCallId: string = "";
  getReviewsAndRatingsApiCallId: string = "";
  updateReviewApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.NavigationIdMessage)
    ];

    this.state = {
      isLoading: false,
      userToken: "",
      pageNo: 1,
      perPage: elementsPerPage,
      totalPages: 1,
      isAlert: false,
      alertMsg: "",
      alertType: "success",
      testimonialsList: [],
      searchText: "",
      isHeaderChecked: false,
      isChecked: [],
      openAddReviewModal: false,
      openDeleteReviewModal: false,
      ratingListData: [
            { id: "1" },
            { id: "2" },
            { id: "3" },
            { id: "4" },
            { id: "5" }
        ],
        ratingId: "1",
        courseId: "",
        courseName: "",
        rating: 5,
        comment: "",
        isUpdate: false,
        loader: false,
        selectedReviewId: "",
        deleteReviewId: "",
        selectedRatingId:""
    };

    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    const token = await getStorageData("loginToken");
    this.setState({ userToken: token });
    this.getTestimonialsList();
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if(apiRequestCallId === this.getTestimonialsListApiCallId) {
          this.handleGetTestimonialsResponse(responseJson);
      }
      else if(apiRequestCallId === this.addReviewApiCallId){
        this.handleAddReviewsResponse(responseJson, 'add');
      }
      else if(apiRequestCallId === this.deleteReviewsAndRatingsApiCallId){
        this.handleDeleteReviewResponse(responseJson);
      }
      else if(apiRequestCallId ===  this.updateReviewApiCallId){
        this.handleAddReviewsResponse(responseJson, 'update');
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  oncloseAlert = () => {
    this.setState({
      isAlert: false
    });
  };

  handleGetTestimonialsResponse(resJson: TestimonialsResponseList) {
    if(resJson.error){
      this.setState({
        testimonialsList: [],
        totalPages: 0,
        isLoading: false
      });
    }else if (
      resJson.errors &&
      resJson.errors.length > 0 
    ) {
      this.navigationLoginScreen();
    } else {
      let count = resJson.meta.total_testimonial / this.state.perPage;
      count = Math.ceil(count);
      this.setState({
        testimonialsList: resJson.data,
        totalPages: count,
        isLoading: false,
        courseName: resJson.data[0].attributes.course_details.course_name,
      });
    }
  }

  handleAddReviewsResponse(resJson: {data:""}, type: string) {
    if (resJson.data) {
      this.setState({ isAlert: true, alertMsg: type == 'add' ? "Review Created successfully!!" : "Review Updated successfully!!", alertType: "success", loader: false });
      this.handleFeildReset();
      this.getTestimonialsList();
      this.handleCloseAddReviewModal();
    } else {
      this.setState({ isAlert: true, alertMsg: "Something went wrong!", alertType: "error", loader: false });
    }
  }

  handlePageChange = (event: any, value: number) => {
    this.setState({ pageNo: value }, () => {
      this.getTestimonialsList();
    });
  };

  async getTestimonialsList() {
    this.setState({ isLoading: true });
    const courseId = await getStorageData('EditId');
    const courseName = await getStorageData('EditCourseName');
    this.setState({ courseId: courseId, courseName: courseName });
    this.getTestimonialsListApiCallId = this.apiCall({
      method: configJSON.validationApiMethodType,
      endPoint:
        configJSON.testimonialsListApiEndpoint +
        `?page=${this.state.pageNo}&per_page=${this.state.perPage}&course_id=${this.state.courseId}`,
      token: this.state.userToken
    });
  };

  navigationLoginScreen = () => {
    alert(configJSON.sessionExpireMsg)     
    const messageLogin: Message = new Message(
      getName(MessageEnum.NavigationEmailLogInMessage)
    );
    messageLogin.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(messageLogin);
  };

  apiCall(data: APIPayload) {
    let { contentType, method, endPoint, body, type, token } = data;
    let requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      ...(contentType ? { "Content-Type": contentType } : {}),
      token
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        !type ? JSON.stringify(body) : body
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  handleReviewHeaderCheckboxChange = () => {
    this.setState(prevState => {
        const isHeaderChecked = !prevState.isHeaderChecked;
        const isChecked = Array(prevState.testimonialsList.length).fill(isHeaderChecked);

        return { isHeaderChecked, isChecked };
    });
};

handleReviewRowCheckboxChange = (index: number) => {
    this.setState(prevState => {
        const isChecked = [...prevState.isChecked];
        isChecked[index] = !isChecked[index];
        const isHeaderChecked = isChecked.every(value => value);

        return { isChecked, isHeaderChecked };
    });
};

handleShowReview = async (reviewItem:{id:string, attributes:{comment: string, rating: number}}) => {
  this.setState({ isUpdate: true, selectedRatingId: reviewItem.id, comment: reviewItem.attributes.comment, rating: reviewItem.attributes.rating},()=>{
    this.handleOpenAddReviewModal();
  });
};

handleDeleteReviewsAndRatings = async (reviewsId: string | number) => {
  this.setState({ loader: true });
  const endPoint = `${configJSON.deleteCourseReviewAPIEndPoint}/${reviewsId}`;
  this.deleteReviewsAndRatingsApiCallId = this.apiCall({
      contentType: configJSON.ReviewsAndRatingsApiContentType,
      method: configJSON.httpDeleteType,
      endPoint,
      token: this.state.userToken,
  });
};

handleDeleteReviewResponse = (response: object) => {
  this.setState({
    openDeleteReviewModal: false,
      deleteReviewId: "",
      loader: false,
      isAlert: true,
      alertMsg: "Review And Rating Deleted Successfully",
      alertType: "success"
  }, () => this.getTestimonialsList());
};

handleOpenDeleteReviewsAndRatingsModal = (reviewId: string | number) => {
  this.setState({ openDeleteReviewModal: true, deleteReviewId: reviewId });
};

handleCloseDeleteReviewsAndRatingsModal = () => {
  this.setState({ openDeleteReviewModal: false, deleteReviewId: "" });
};

handleReviewsAndRatingsSearch = (event: { target: { value: string } }) => {
  // handle search ratings
};

handlePostCourseReview = async () => {
    this.setState({ loader: true })
    const { courseId, rating, comment } = this.state;
    if (!comment.trim()) {
      this.setState({ isAlert: true, alertMsg: "Comment cannot be blank", alertType: "error", loader: false });
      return;
    }
    let body = {
      course_id: parseInt(courseId),
      rating: rating,
      comment: comment,
    }
    this.addReviewApiCallId = this.apiCall({
      contentType: configJSON.exampleApiContentType,
      method: configJSON.httpPostType,
      endPoint: configJSON.addEditReviewApiEndPoint,
      body: body,
      token: this.state.userToken
    })
  };

handleRatingSelectChange = (reviewId: unknown) => {
    this.setState({
        rating: reviewId as number
    });
};

handleCommentChange = (event: { target: { value: string } }) => {
  this.setState({
      comment: event.target.value
  });
};

handleOpenAddReviewModal = () => {
    this.setState({
        openAddReviewModal: true,
    });
};

handleCloseAddReviewModal = () => {
    this.setState({ openAddReviewModal: false });
};

handleFeildReset = () => {
  this.setState({
      isUpdate: false,
      ratingId: "",
      comment: "",
  });
};

  handleSidebarItemClick = (route: string) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), route);
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };
  handleUpdatePostCourseReview=()=>{
    this.setState({ loader: true })
    const { courseId, rating, comment } = this.state;
    if (!comment.trim()) {
      this.setState({ isAlert: true, alertMsg: "Comment cannot be blank", alertType: "error", loader: false });
      return;
    }
    let body = {
      course_id: parseInt(courseId),
      rating: rating,
      comment: comment,
    }
    this.updateReviewApiCallId = this.apiCall({
      contentType: configJSON.exampleApiContentType,
      method: configJSON.httpPutType,
      endPoint: `${configJSON.updateCourseReviewAPIEndPoint}/${this.state.selectedRatingId}`,
      body: body,
      token: this.state.userToken
    })
  }
  // Customizable Area End
}
// Customizable Area Start
// Customizable Area End
