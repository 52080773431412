
// Customizable Area Start
import React from "react";
import {
    Box,
    Button,
    MenuItem,
    Modal,
    Select,
    TextField,
    Typography,
    styled,
} from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
const drawerWidth = 250;
const closeDrawer = 0;
import {
    TableBody,
    TableStyle,
} from "../../educational-user-profile/src/EducationalUserProfile.web";
import MainLayout from "../../../components/src/MainLayout.web";
import NotificationAlertMessage from "../../../components/src/NotificationAlertMessage";
import { Wrapper } from "./AddAdminCourses.web";
import CoursesSidebar from "./components/CoursesSidebar.web";
import { PiPen } from "react-icons/pi";
import { FaRegTrashAlt, FaChevronDown } from "react-icons/fa";
import { RxCross2 } from "react-icons/rx";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import CourseReviewsAndRatingsController, {
    Props,
    TestimonialData,
    configJSON,
} from "./CourseReviewsAndRatingsController.web";
import { ButtonBox, ReviewFieldBox, ReviewsAndRatingsCancelButton, ReviewsAndRatingsDeleteButton, ReviewsAndRatingsDeleteModal, ReviewsAndRatingsDeleteText, ReviewsAndRatingsModalButton, ReviewsAndRatingsModalView } from "./AdminReviewsAndRatings.web";
import { CourseChapterInputBox, CourseClassesFieldBox, CourseClassesFieldControlBox } from "./AdminCourseClasses.web";

const menuItems = [
    {
        text: "Course Edit",
        path: configJSON.courseEditPath,
        active: false
    },
    {
        text: "Course Chapter",
        path: configJSON.courseChapterPath,
        active: false
    },
    {
        text: "Course Classes",
        path: configJSON.courseClassesPath,
        active: false
    },
    {
        text: "Reviews & Ratings",
        path: configJSON.courseRatingsPath,
        active: true
    },
];
// Customizable Area End

export default class CourseReviewsAndRatings extends CourseReviewsAndRatingsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    renderStars = (count: number) => {
        const stars = [];
        for (let i = 0; i < count; i++) {
            stars.push(<span key={i}>&#9733;</span>);
        }
        return stars;
    };

    renderRatingSelect = () => {
        return (
            <ReviewFieldControlBox>
                <Typography className="input_txt">Rating*</Typography>
                <SelectBox
                    id="demo-customized-select-native"
                    disableUnderline
                    IconComponent={FaChevronDown}
                    data-test-id="ratingSelectTestId"
                    onChange={(event) => this.handleRatingSelectChange(event.target.value)}
                    value={this.state.rating}
                >
                    <MenuItem aria-label="None" value="" disabled />
                    {this.state.ratingListData.map((rating: { id: string }) => {
                        return (
                            <MenuItem key={rating.id} value={rating.id}>
                                {rating.id}
                            </MenuItem>
                        );
                    })}
                </SelectBox>
            </ReviewFieldControlBox>
        )
    };

    renderAddReviewModal = () => {
        return (
            <>
                <AddReviewModal
                    style={{ backgroundColor: "rgba(255, 255, 255, .4)" }}
                    data-test-id="AddReviewModalId"
                    open={this.state.openAddReviewModal}
                    onClose={this.handleCloseAddReviewModal}
                >
                    <AddReviewModalView>
                    <NotificationAlertMessage
                            alertType={this.state.alertType}
                            msg={this.state.alertMsg}
                            onClose={this.oncloseAlert}
                            isOpen={this.state.isAlert}
                            data-test-id="notificationAlertMessage"
                        />
                        <AddReviewModalBox>
                            <ModalHeadingText>{configJSON.addReviewsAndRatingsText}</ModalHeadingText>
                            <RxCross2 onClick={() => this.handleCloseAddReviewModal()} style={{ height: "25px", width: "25px" }} />
                        </AddReviewModalBox>
                        <CourseClassesFieldBox>
                            <CourseClassesFieldControlBox>
                                <Typography className="input_txt">{configJSON.courseFieldText}</Typography>
                                <div
                                    style={{
                                        display: "flex",
                                        width: "calc(100% - 160px)",
                                        flexWrap: "wrap",
                                    }}
                                >
                                    <CourseChapterInputBox
                                        variant="outlined" fullWidth
                                        data-test-id="courseInputTestId"
                                        value={this.state.courseName}
                                        disabled={true}
                                    />
                                </div>
                            </CourseClassesFieldControlBox>
                        </CourseClassesFieldBox>
                        <ReviewFieldBox>
                            {this.renderRatingSelect()}
                        </ReviewFieldBox>
                        <ReviewFieldControlBox>
                            <Typography className="input_txt">{configJSON.commentFieldText}</Typography>
                            <MultiLineTextField
                                data-test-id="commentInputTestId"
                                variant="outlined"
                                multiline
                                name="comment"
                                onChange={this.handleCommentChange}
                                value={this.state.comment}
                            />
                        </ReviewFieldControlBox>
                        <ButtonBox>
                            {this.state.isUpdate ?
                                <Button variant="contained" className="reset_btn"
                                    onClick={() => this.handleCloseAddReviewModal()}
                                >
                                    {configJSON.cancelBtnText}
                                </Button> :
                                <Button variant="contained" className="reset_btn"
                                    onClick={this.handleFeildReset}
                                >
                                    {configJSON.resetBtnText}
                                </Button>}
                            {this.state.isUpdate ?
                                <Button
                                    data-test-id="updateButtonTestId"
                                    onClick={this.handleUpdatePostCourseReview}
                                    disabled={this.state.loader}
                                    variant="contained" className="create_btn">
                                    {configJSON.updateBtnText}
                                </Button> :
                                <Button
                                    data-test-id="addButtonTestId"
                                    onClick={this.handlePostCourseReview}
                                    disabled={this.state.loader}
                                    variant="contained" 
                                    className="create_btn">
                                    {configJSON.addReviewsAndRatingsBtnText}
                                </Button>}
                        </ButtonBox>
                    </AddReviewModalView>
                </AddReviewModal>
            </>
        )
    };

    renderDeleteReviewsAndRatingsModal = () => {
        return (
            <>
                <ReviewsAndRatingsDeleteModal
                    style={{ backgroundColor: "rgba(255, 255, 255, .4)" }}
                    data-test-id="ReviewDeleteModalId"
                    open={this.state.openDeleteReviewModal}
                    onClose={this.handleCloseDeleteReviewsAndRatingsModal}
                >
                    <ReviewsAndRatingsModalView>
                        <ReviewsAndRatingsDeleteText component={"p"}>
                            Do you want to delete this review and rating?
                        </ReviewsAndRatingsDeleteText>
                        <ReviewsAndRatingsModalButton>
                            <ReviewsAndRatingsDeleteButton data-test-id="deleteBtnTestId"
                                onClick={() => this.handleDeleteReviewsAndRatings(this.state.deleteReviewId)}
                            >
                                Delete
                            </ReviewsAndRatingsDeleteButton>
                            <ReviewsAndRatingsCancelButton data-test-id="cancelBtnTestId"
                                onClick={() => this.handleCloseDeleteReviewsAndRatingsModal()}
                            >
                                Cancel
                            </ReviewsAndRatingsCancelButton>
                        </ReviewsAndRatingsModalButton>
                    </ReviewsAndRatingsModalView>
                </ReviewsAndRatingsDeleteModal>
            </>
        )
    };

    renderTableBody = (item: TestimonialData, index: number) => {
        return (
            <>
                <tr>
                    <td>
                        <Box className="desc_checkbox">
                            <input
                                data-test-id={"rowCheckboxTestId"}
                                type="checkbox"
                                style={ReviewsAndRatingsDrawerStyle.input}
                                checked={this.state.isChecked[index]}
                                onChange={() => this.handleReviewRowCheckboxChange(index)}
                            />
                        </Box>
                    </td>
                    <td style={{ textAlign: "left" }}>{item.id}</td>
                    <td style={{ textAlign: "left" }}>
                        {item.attributes.user_name.full_name}
                    </td>
                    <td style={{ textAlign: "left" }}>
                        {item.attributes.user_name.full_phone_number}
                    </td>
                    <td style={{ textAlign: "left" }}>
                        {item.attributes.user_name.email}
                    </td>
                    <td style={{ textAlign: "left" }}>
                        {item.attributes.rating
                            ? this.renderStars(item.attributes.rating)
                            : "----"}
                    </td>
                    <td style={{ textAlign: "left" }}>
                        {item.attributes.comment.length > 50
                            ? `${item.attributes.comment.slice(0, 50)}...`
                            : item.attributes.comment || "----"}
                    </td>
                    <td>
                        <Box className="desc_edit_icon_box">
                            <PiPen
                                data-test-id={'editIconTestId'}
                                className="desc_edit_icon"
                                color={"black"}
                                size={20}
                                onClick={() => this.handleShowReview(item)}
                            />
                            <FaRegTrashAlt
                                data-test-id={'deleteIconTestId'}
                                className="desc_edit_icon"
                                color={"black"}
                                size={17}
                                onClick={() => this.handleOpenDeleteReviewsAndRatingsModal(item.id)}
                            />
                        </Box>
                    </td>
                </tr>
                <Box className="table_divider" style={webStyles.tableDivide} />
            </>
        );
    };

    renderTable = () => {
        return (
            <Box>
                <TableStyle>
                    <thead>
                        <tr>
                            <th>
                                <Box
                                    style={{
                                        display: "flex",
                                        width: "100%",
                                        justifyContent: "center",
                                    }}
                                >
                                    <input
                                        type="checkbox"
                                        data-test-id="headerCheckboxTestId"
                                        style={ReviewsAndRatingsDrawerStyle.input}
                                        onChange={this.handleReviewHeaderCheckboxChange}
                                        checked={this.state.isHeaderChecked}
                                    />
                                </Box>
                            </th>
                            {["ID", "Name", "Phone", "Email", "Rating", "Reviews", "Action" ].map(
                                (header, index) => (
                                    <th key={index.toString()}>
                                        <Box className="th_box" style={{ justifyContent: "start" }}>
                                            <Typography className="title_th">{header}</Typography>
                                        </Box>
                                    </th>
                                )
                            )}
                        </tr>
                    </thead>
                    <span style={webStyles.lineDivide} />
                    <TableBody>
                        {this.state.testimonialsList.length > 0 && this.state.testimonialsList.map((item, index) =>
                            this.renderTableBody(item, index)
                        )}
                    </TableBody>
                </TableStyle>
                {this.state.testimonialsList.length > 0 ? 
                <PaginationBox
                    style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                        marginTop: "20px"
                    }}
                >
                    <Pagination
                        data-test-id="pageChangebutton"
                        count={this.state.totalPages}
                        shape="rounded"
                        boundaryCount={1}
                        page={this.state.pageNo}
                        siblingCount={0}
                        onChange={this.handlePageChange}
                    />
                </PaginationBox>
                :<Box style={{ height: '100vh', justifyContent: 'center', alignItems: 'center', display: 'flex', overflow: 'hidden' }}>
                <Typography style={{ color: 'black' }}>{configJSON.recordNotFound}</Typography>
              </Box>}
            </Box>
        );
    };

    // Customizable Area End

    render() {
        // Customizable Area Start
        return (
            <MainLayout
                data-test-id="mainLayoutEvent"
                handleSearch={(e: any) => this.handleReviewsAndRatingsSearch(e)}
                isLoading={this.state.isLoading}
                title={"Reviews & Ratings"}
                actionButtons={[
                    {
                        icon: <AddCircleOutlineIcon />,
                        iconType: "jsxElement",
                        onClick: () => this.handleOpenAddReviewModal(),
                        text: "Create Review",
                        testId: "createReviewButtonId"
                    }
                    ]}
                isStudent={false}
            >
                <NotificationAlertMessage
                    alertType={this.state.alertType}
                    msg={this.state.alertMsg}
                    onClose={this.oncloseAlert}
                    isOpen={this.state.isAlert}
                    data-test-id="notificationAlertMessage"
                />
                {this.renderAddReviewModal()}
                {this.renderDeleteReviewsAndRatingsModal()}
                <Wrapper>
                    <CoursesSidebar data-test-id="coursesSidebar" menuItems={menuItems} onItemClick={this.handleSidebarItemClick} />
                    <main className="table_content">{this.renderTable()}</main>
                </Wrapper>
            </MainLayout>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const PaginationBox = styled(Box)({
    "& .viewall_txt": {
        color: "#206575",
        fontSize: "0.75rem",
        cursor: "pointer"
    },
    "& .MuiPaginationItem-page.Mui-selected": {
        backgroundColor: "#206575",
        color: "#fff"
    }
});

const webStyles = {
    showDrawer: {
        width: `calc(100% - ${drawerWidth}px)`
    },
    categoryImgName: {
        display: "inline-flex",
        gap: "1rem",
        alignItems: "center",
        width: "100%",
        height: "100%"
    },
    checkboxMain: {
        display: "flex",
        width: "100%",
        justifyContent: "center"
    },
    lineDivide: {
        height: "20px",
        display: "inline-block"
    },
    tableDivide: {
        height: "10px"
    },
    input: {
        width: "20px",
        height: "20px",
        border: "3px solid black",
        background: "none",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    }
};

const ReviewsAndRatingsDrawerStyle = {
    hideChildCatDrawer: { width: `calc(100% - ${closeDrawer}px)` },
    drawerTableDivider: { height: "8px" },
    showChildCatDrawer: { width: `calc(100% - ${drawerWidth}px)` },
    input: {
        width: "20px",
        height: "20px",
        border: "3px solid black",
        background: "none",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    drawerDividerLine: {
        display: "inline-block",
        height: "20px",
    },
};

const ReviewsAndRatingsCheckStatusBtn = styled("label")({
    "& .slider": {
        right: 0,
        left: 0,
        cursor: "pointer",
        transition: ".4s",
        position: "absolute",
        top: 0,
        bottom: "-1px",
        WebkitTransition: ".4s",
        borderRadius: "20px",
        backgroundColor: "#ccc",
    },
    "&.switch": {
        width: "36px",
        height: "19px",
        position: "relative",
        marginTop: "5px",
        display: "inline-block",
        "& input": {
            width: 0,
            height: 0,
            opacity: 0,
        },
    },
    "& input:checked + .slider": { 
        backgroundColor: "#708328" 
    },
    "& .slider:before": {
        width: "18px",
        height: "18px",
        content: '""',
        left: "1px",
        borderRadius: "50%",
        position: "absolute",
        bottom: "1px",
        WebkitTransition: ".4s",
        backgroundColor: "white",
        transition: ".4s"
    },
    "& input:checked + .slider:before": { 
        left: "-10px" 
    }
});

const AddReviewModal = styled(Modal)({
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
});

const AddReviewModalView = styled(Box)({
    width: "651px",
    height: "400px",
    borderRadius: '18px',
    padding: "10px 30px",
    overflowY: "auto",
    backgroundColor: '#ffffff',
    margin: "20px",
    outline: "none",
    boxShadow: "0px 0px 3px px transparent",
    display: "flex",
    flexDirection: "column"
});

const AddReviewModalBox = styled(Box)({
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    paddingTop: 10,
    paddingBottom: 22
});

const ModalHeadingText = styled(Typography)({
    fontStyle: "normal",
    fontSize: "18px",
    fontFamily: "Inter",
    fontWeight: 700,
    color: "#000",
    letterSpacing: "-0.12px",
    lineHeight: "26px",
});

const ReviewFieldControlBox = styled(Box)({
    justifyContent: "flex-start",
    display: "flex",
    alignItems: "center",
    margin: "10px 0",
    "& .file_upload_box": {
        width: "490px",
        height: "44px",
        color: "#fff",
        border: "1px solid black",
        display: "flex",
        justifyContent: "space-between",
        borderRadius: "4px",
        alignItems: "center",
        borderColor: "#D4D4D4",
        "& .lable_txt": {
            alignItems: "center",
            display: "flex",
            height: "90%",
            marginRight: "2px",
            borderRadius: "4px",
            backgroundColor: "#3F6474",
            fontSize: "12px",
            justifyContent: "center",
            padding: "0px 30px",
        },
        "& .app_icon_txt": {
            color: "#A9A9A9",
            fontSize: "13px",
            wordSpacing: "-2px",
            marginLeft: "10px",
        },
    },
    "& .input_txt": {
        width: "160px",
        fontFamily: "Inter",
        fontStyle: "normal",
        textTransform: "capitalize",
        color: "#192252",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "24px"
    },
    "@media (max-width:875px)": {
        alignItems: "start",
        flexDirection: "column",
        "& .input_txt": {
            width: "100%",
            marginBottom: "8px",
        }
    },
});

const SelectBox = styled(Select)({
    "&.MuiInputBase-root": {
        width: "490px",
        borderRadius: "4px",
        border: "1px solid lightgray",
    },
    "& .MuiNativeSelect-icon": {
        right: "6px",
        top: "calc(50% - 7px);",
    },
    "& .MuiInputBase-input": { padding: "10px 0px 10px 8px" },
    "&  .MuiSelect-icon": {
    right: "10px",
  },
});

const MultiLineTextField = styled(TextField)({
    width: '75%',
    '& .MuiInputBase-input': { 
        boxSizing: 'border-box',
        height: '90px',
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': { 
        borderColor: '#3F6474' 
    },
    '& .MuiInputBase-inputMultiline': {
        height: '60px !important',
    }
    },
  });
// Customizable Area End