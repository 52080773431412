import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  Typography,
  styled,
  Modal,
  TextField,
  Select,
  MenuItem,
} from "@material-ui/core";
import { FaChevronDown } from "react-icons/fa";
import { RxCross2 } from "react-icons/rx";
import { ProfilePicInput } from "../../../components/src/GenericInputs";
import { Pagination } from "@material-ui/lab";
import { PiPen } from "react-icons/pi";
import { FaRegTrashAlt } from "react-icons/fa";
import CircularProgress from "@material-ui/core/CircularProgress";
import { webStyles } from "../../ContentManagement/src/AdminCourses.web";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
const drawerWidth = 250;
const closeDrawer = 0;
import {
  TableBody,
  TableStyle,
} from "../../educational-user-profile/src/EducationalUserProfile.web";
import MainLayout from "../../../components/src/MainLayout.web";
import NotificationAlertMessage from "../../../components/src/NotificationAlertMessage";
import { renderTableRecord } from "../../user-profile-basic/src/AdminDistrictList.web";
import moment from "moment";
import { Wrapper } from "./AddAdminCourses.web";
import CoursesSidebar from "./components/CoursesSidebar.web";
// Customizable Area End

import ContentManagementController, {
  Props,
  configJSON,
} from "./ContentManagementController";

export default class ContentManagement extends ContentManagementController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  menuItems = [
    { 
      text: "Course Edit",
      path: configJSON.courseEditPath,
      active: false
    },
    { 
      text: "Course Chapter",
      path: configJSON.courseChapterPath,
      active: true 
    },
    { 
      text: "Course Classes",
      path: configJSON.courseClassesPath,
      active: false 
    },
    { 
      text: "Reviews & Ratings",
      path: configJSON.courseRatingsPath, 
      active: false 
    },
  ];

  renderTableRowCourseChapters = (item: any, index: any) => {
    return (
      <>
        <tr>
          <td>
            <Box className="desc_checkbox">
              <input
                data-test-id={"rowCheckboxTestId"}
                type="checkbox"
                checked={this.state.isChecked[index]}
                onChange={() =>
                  this.handleCourseChaptersRowCheckboxChange(index)
                }
                style={courseChapterDrawerStyle.input}
              />
            </Box>
          </td>
          <td className="tr_desc">{item?.id}</td>
          <td>
            <img
              src={item?.attributes?.course_chapter_thumbnail || "----"}
              alt="img"
              className="desc_img"
            />
          </td>
          <td>
            {item.attributes.name?.length > 55
              ? `${item.attributes.name.slice(0, 55)}...`
              : item.attributes.name || "----"}
          </td>
          <td>{item.attributes.no_of_classes || "----"}</td>
          <td>{moment(item.attributes.created_at).format("DD/MM/YY")}</td>
          <td>{item.attributes.sequence_number}</td>
          <td>
            {
              <CourseChapterstatusBtn className="switch">
                <input
                  data-test-id={"statusCheckTestId"}
                  type="checkbox"
                  onChange={() => this.handleCourseChapterStatus(item.id)}
                  checked={item.attributes.status}
                />
                <span className="slider round"></span>
              </CourseChapterstatusBtn>
            }
          </td>
          <td>
            <Box className="desc_edit_icon_box">
              <PiPen
                data-test-id={"editIconTestId"}
                className="desc_edit_icon"
                color={"black"}
                size={20}
                onClick={() => this.handleShowCourseChapters(item.id)}
              />
              <FaRegTrashAlt
                data-test-id={"deleteIconeTestId"}
                className="desc_edit_icon"
                color={"black"}
                size={17}
                onClick={() => this.handleOpenDeleteCourseChapterModal(item.id)}
              />
            </Box>
          </td>
        </tr>
        <Box
          className="table_divider"
          style={courseChapterDrawerStyle.drawerTableDivider}
        />
      </>
    );
  };

  getSortTestId = (header: string) => {
    const headerMap: { [key: string]: string } = {
      "Chapter Name": "sort_name",
      "S.No": "sort_id",
      Status: "sort_status",
    };

    return headerMap[header] || "sort";
  };

  renderTableHeaderCourseChapters = () => {
    const isCurrentSort = (name: string) =>
      this.state.currentSortState.field === name &&
      this.state.currentSortState.order === "asc";
    return (
      <Box>
        <TableStyle>
          <thead>
            <tr>
              <th>
                <Box
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "center",
                  }}
                >
                  <input
                    type="checkbox"
                    data-test-id="headerCheckboxTestId"
                    style={courseChapterDrawerStyle.input}
                    checked={this.state.isHeaderChecked}
                    onChange={this.handleCourseChaptersHeaderCheckboxChange}
                  />
                </Box>
              </th>
              {[
                "S.No",
                "Thumbnail",
                "Chapter Name",
                "Nos. of classes",
                "Created Date",
                "Sequence Number",
                "Status",
                "Action",
              ].map((header, index) => {
                let renderIcon = false;
                if (["S.No", "Chapter Name", "Status", "Sequence Number"].includes(header)) {
                  renderIcon = true;
                }
                return renderTableRecord(
                  () => isCurrentSort(header),
                  header,
                  renderIcon,
                  index,
                  () => this.handleSort(header),
                  this.getSortTestId(header),
                  "center"
                );
              })}
            </tr>
          </thead>
          <span style={courseChapterDrawerStyle.drawerDividerLine}></span>
          <TableBody>
            {this.state.adminCourseChaptersData.data.length > 0 && this.state.adminCourseChaptersData.data.map(
              (item: any, index: any) =>
                this.renderTableRowCourseChapters(item, index)
            )}
          </TableBody>
        </TableStyle>
        {this.state.adminCourseChaptersData.data.length > 0 ? 
        <CourseChapterPaginationBox
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            marginTop: "20px",
          }}
        >
          <Pagination
            data-test-id="pageChangebutton"
            count={this.state.pageCount}
            shape="rounded"
            boundaryCount={1}
            page={this.state.currentPageCount}
            siblingCount={0}
            onChange={this.onCourseChaptersPageChange}
          />
        </CourseChapterPaginationBox>
          : <Box style={{height:'100vh', justifyContent: 'center', alignItems: 'center', display: 'flex', overflow: 'hidden' }}>
            <Typography style={{ color: 'black' }}>{configJSON.recordNotFound}</Typography>
          </Box>}
      </Box>
    );
  };

  renderDeleteInstituteModal = () => {
    return (
      <>
        <CourseChapterDeleteModal
          style={{ backgroundColor: "rgba(255, 255, 255, .4)" }}
          data-test-id="InstituteDeleteModalId"
          open={this.state.openDeleteCourseChapterModal}
          onClose={this.handleCloseDeleteCourseChapterModal}
        >
          <CourseChapterModalView>
            <CourseChapterDeleteText component={"p"}>
              Do you want to delete this course chapter?
            </CourseChapterDeleteText>
            <CourseChapterModalButton>
              <CourseChapterDeleteButton
                data-test-id="deleteBtnTestId"
                onClick={() =>
                  this.handleDeleteCourseChapter(
                    this.state.deleteCourseChapterId
                  )
                }
              >
                Delete
              </CourseChapterDeleteButton>
              <CourseChapterCancelButton
                data-test-id="cancelBtnTestId"
                onClick={() => this.handleCloseDeleteCourseChapterModal()}
              >
                Cancel
              </CourseChapterCancelButton>
            </CourseChapterModalButton>
          </CourseChapterModalView>
        </CourseChapterDeleteModal>
      </>
    );
  };

  renderAddCourseChapterModal = () => {
    const learningMaterialName = this.state.learningMaterialName;
    const learningMaterialNameText =
      learningMaterialName === ""
        ? "Upload PDF file"
        : learningMaterialName?.length > 40
        ? `${learningMaterialName?.substring(0, 40)}...`
        : learningMaterialName;

    return (
      <>
        <AddCourseChapterModal
          style={{ backgroundColor: "rgba(255, 255, 255, .4)" }}
          data-test-id="AddInstituteModalId"
          open={this.state.openAddCourseChapterModal}
          onClose={this.handleCloseCourseChapterModal}
        >
          <AddCourseChapterModalView>
            <AddCourseChapterModalBox>
              <AddCourseChapterHeadingText>
                Add Course Chapter
              </AddCourseChapterHeadingText>
              <RxCross2
                onClick={() => this.handleCloseCourseChapterModal()}
                style={{ height: "25px", width: "25px", marginRight:'20px' }}
              />
            </AddCourseChapterModalBox>
            <AddCourseChapterModalInnerView>
            <CourseChapterFieldBox>
              {this.renderCourseSelect()}
            </CourseChapterFieldBox>
            <CourseChapterFieldBox>
              <CourseChapterFieldControlBox>
                <Typography className="input_txt">Chapter Name*</Typography>
                <div
                  style={{
                    display: "flex",
                    width: "calc(100% - 160px)",
                    flexWrap: "wrap",
                  }}
                >
                  <CourseChapterInputBox
                    variant="outlined"
                    fullWidth
                    data-test-id="chapterNameInputTestId"
                    onChange={this.handleCourseChapterName}
                    value={this.state.chapterName}
                  />
                  <span style={webStyles.errorText}>
                    {this.state.chapterNameError}
                  </span>
                </div>
              </CourseChapterFieldControlBox>
            </CourseChapterFieldBox>
            <CourseChapterFieldBox>
              <CourseChapterFieldControlBox>
                <Typography className="input_txt">Short Details*</Typography>
                <div
                  style={{
                    display: "flex",
                    width: "calc(100% - 160px)",
                    flexWrap: "wrap",
                  }}
                >
                  <CourseChapterInputBox
                    variant="outlined"
                    fullWidth
                    data-test-id="shortDetailsInputTestId"
                    onChange={this.handleShortDetails}
                    value={this.state.shortDetails}
                  />
                  <span style={webStyles.errorText}>
                    {this.state.shortDetailsError}
                  </span>
                </div>
              </CourseChapterFieldControlBox>
            </CourseChapterFieldBox>
            <CourseChapterFieldBox>
              {this.renderSequenceNumberSelect()}
            </CourseChapterFieldBox>
            <CourseChapterFieldBox>
              <CourseChapterFieldControlBox>
                <Typography className="input_txt">Upload Thumbnail*</Typography>
                <div
                  style={{
                    display: "flex",
                    width: "calc(100% - 160px)",
                    flexWrap: "wrap",
                  }}
                >
                  {this.state.thumbnailPreview ? (
                    <ProfilePicInput
                      onChange={this.handleThumbnailImage}
                      onRemove={this.handleDeleteThumbnail}
                      data-test-id="uploadThumbnailId"
                      name="thumbnailImageUpload"
                      previewThumbnail={this.state.thumbnailPreview}
                    />
                  ) : (
                    <Box className="file_upload_box">
                      <Typography className="app_icon_txt">
                        Upload square image icon
                      </Typography>
                      <input
                        data-test-id="uploadThumbnailId"
                        multiple
                        type="file"
                        accept="image/*"
                        name="thumbnailImageUpload"
                        id="thumbnailImageInput"
                        style={{ display: "none" }}
                        onChange={this.handleThumbnailImage}
                      />
                      <label
                        htmlFor="thumbnailImageInput"
                        className="lable_txt"
                      >
                        Browse
                      </label>
                    </Box>
                  )}
                  <span style={webStyles.errorText}>
                    {this.state.thumbnailError}
                  </span>
                </div>
              </CourseChapterFieldControlBox>
            </CourseChapterFieldBox>
            <CourseChapterFieldBox>
              <CourseChapterFieldControlBox>
                <Typography className="input_txt">
                  Upload Learning Material
                </Typography>
                <div
                  style={{
                    display: "flex",
                    width: "calc(100% - 160px)",
                    flexWrap: "wrap",
                  }}
                >
                  <Box className="file_upload_box">
                    <Typography className="app_icon_txt">
                      {learningMaterialNameText}
                    </Typography>
                    <input
                      data-test-id="uploadLearningMaterialId"
                      multiple
                      type="file"
                      accept="application/pdf"
                      name="learningMaterialFileUpload"
                      id="learningMaterialInput"
                      style={{ display: "none" }}
                      onChange={this.handleLearningMaterial}
                    />
                    <label
                      htmlFor="learningMaterialInput"
                      className="lable_txt"
                    >
                      Browse
                    </label>
                  </Box>
                  <span style={webStyles.errorText}>
                    {this.state.learningMaterialError}
                  </span>
                </div>
              </CourseChapterFieldControlBox>
            </CourseChapterFieldBox>
            <CourseChapterFieldBox>
              <CourseChapterFieldControlBox>
                <Typography className="input_txt">status</Typography>
                <CourseChapterheckBodButton className="switch">
                  <input
                    type="checkbox"
                    data-test-id="checkboxId"
                    onChange={this.handleCourseChapterStatusCheck}
                    checked={this.state.courseChapterStatus}
                  />
                  <span className="slider round"></span>
                </CourseChapterheckBodButton>
              </CourseChapterFieldControlBox>
            </CourseChapterFieldBox>
            <CourseChapterButtonBox>
              {this.state.isUpdate ? (
                <Button
                  variant="contained"
                  className="reset_btn"
                  onClick={() => this.handleCloseCourseChapterModal()}
                >
                  Cancel
                </Button>
              ) : (
                <Button
                  variant="contained"
                  className="reset_btn"
                  onClick={this.handleFeildReset}
                >
                  Reset
                </Button>
              )}
              {this.state.isUpdate ? (
                <Button
                  data-test-id="updateButtonTestId"
                  onClick={this.handleEditCourseChapter}
                  variant="contained"
                  disabled={this.state.loader}
                  className="create_btn"
                >
                  {this.state.loader ? (
                    <CircularProgress size={20} color="inherit" />
                  ) : (
                    "Update"
                  )}
                </Button>
              ) : (
                <Button
                  data-test-id="addChapterButtonTestId"
                  onClick={this.handlePostCourseChapter}
                  disabled={this.state.loader}
                  variant="contained"
                  className="create_btn"
                >
                  {this.state.loader ? (
                    <CircularProgress size={20} color="inherit" />
                  ) : (
                    "Add Chapter"
                  )}
                </Button>
              )}
            </CourseChapterButtonBox>
            </AddCourseChapterModalInnerView>
          </AddCourseChapterModalView>
        </AddCourseChapterModal>
      </>
    );
  };

  renderCourseSelect = () => {
    return (
      <CourseChapterFieldControlBox>
        <Typography className="input_txt">Course*</Typography>
        <div
          style={{
            display: "flex",
            width: "calc(100% - 160px)",
            flexWrap: "wrap",
          }}
        >
          <SelectBox
            id="demo-customized-select-native"
            disableUnderline
            IconComponent={FaChevronDown}
            data-test-id="courseSelectTestId"
            onChange={(e) => this.handleCourseSelectChange(e.target.value)}
            value={this.state.courseId}
            disabled={this.state.courseId ? true : false}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left",
              },
              style: {
                height: "350px",
                width: "450px",
                top: "8px",
              },
              getContentAnchorEl: null,
              keepMounted: true,
            }}
          >
            {this.state.courseListData.map((course: any) => {
              return (
                <MenuItem
                  key={course.id}
                  value={course.id}
                  style={{ width: "490px", display: "flex", flexFlow: "wrap" }}
                >
                  <Typography style={{ whiteSpace: "pre-line" }}>
                    {course.attributes.course_name}
                  </Typography>
                </MenuItem>
              );
            })}
          </SelectBox>
          <span style={webStyles.errorText}>{this.state.courseIdError}</span>
        </div>
      </CourseChapterFieldControlBox>
    );
  };

  renderSequenceNumberSelect = () => {
    return (
      <CourseChapterFieldControlBox>
        <Typography className="input_txt">Sequence Number</Typography>
        <div
          style={{
            display: "flex",
            width: "calc(100% - 160px)",
            flexWrap: "wrap",
          }}
        >
          <SelectBox
            id="sequenceSelect"
            disableUnderline
            IconComponent={FaChevronDown}
            data-test-id="sequenceSelect"
            onChange={(e) => this.handleSequenceSelectChange(e.target.value)}
            value={this.state.SequenceNumber}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left",
              },
              style: {
                height: "350px",
                width: "450px",
                top: "8px",
              },
              getContentAnchorEl: null,
              keepMounted: true,
            }}
          >
      {this.state.contentSequenceNumberArray.map((_, index) => {
              const number = index + 1;
              return (
                <MenuItem
                  key={number}
                  value={number}
                  style={{ width: "490px", display: "flex", flexFlow: "wrap" }}
                >
                  <Typography style={{ whiteSpace: "pre-line" }}>
                    {number}
                  </Typography>
                </MenuItem>
              );
            })}
          </SelectBox>
          <span style={webStyles.errorText}>{this.state.contentSequenceNumberError}</span>
        </div>
      </CourseChapterFieldControlBox>
    );
  };
  // Customizable Area End

  render() {
    
    return (
      // Customizable Area Start
      <MainLayout
        data-test-id="mainLayoutEvent"
        handleSearch={(e: any) => this.handleCourseChapterSearch(e)}
        isLoading={this.state.loader}
        title={"Course Chapters"}
        actionButtons={[
          {
            icon: <AddCircleOutlineIcon />,
            iconType: "jsxElement",
            onClick: () => this.handleOpenAddCourseChapterModal(),
            text: "Create Course Chapters",
            testId: "createButtonId",
          },
        ]}
        isStudent={false}
      >
        <NotificationAlertMessage
          alertType={this.state.alertType}
          isOpen={this.state.isAlert}
          msg={this.state.alertMsg}
          onClose={this.onCloseAlert}
        />
        {this.renderDeleteInstituteModal()}
        {this.renderAddCourseChapterModal()}
        <Wrapper>
          <CoursesSidebar data-test-id="coursesSidebar" menuItems={this.menuItems} onItemClick={this.handleSidebarItemClick} />
          <main className="table_content">
            {this.renderTableHeaderCourseChapters()}
          </main>
        </Wrapper>
      </MainLayout>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const courseChapterDrawerStyle = {
  drawerTableDivider: { height: "8px" },
  hideChildCatDrawer: { width: `calc(100% - ${closeDrawer}px)` },
  showChildCatDrawer: { width: `calc(100% - ${drawerWidth}px)` },
  input: {
    height: "20px",
    border: "3px solid black",
    width: "20px",
    display: "flex",
    background: "none",
    alignItems: "center",
    justifyContent: "center",
  },
  drawerDividerLine: {
    height: "20px",
    display: "inline-block",
  },
};

const CourseChapterPaginationBox = styled(Box)({
  "& .MuiPaginationItem-page.Mui-selected": {
    backgroundColor: "#206575",
    color: "#fff",
  },
  "& .viewall_txt": {
    fontSize: "14px",
    cursor: "pointer",
    color: "#206575",
  },
});

const CourseChapterstatusBtn = styled("label")({
  "& .slider": {
    cursor: "pointer",
    position: "absolute",
    right: 0,
    bottom: "-1px",
    top: 0,
    left: 0,
    WebkitTransition: ".4s",
    backgroundColor: "#ccc",
    transition: ".4s",
    borderRadius: "20px",
  },
  "&.switch": {
    display: "inline-block",
    position: "relative",
    marginTop: "5px",
    height: "19px",
    width: "36px",
    "& input": {
      width: 0,
      opacity: 0,
      height: 0,
    },
  },
  "& input:checked + .slider": { backgroundColor: "#708328" },
  "& .slider:before": {
    position: "absolute",
    left: "1px",
    content: '""',
    height: "18px",
    width: "18px",
    WebkitTransition: ".4s",
    borderRadius: "50%",
    bottom: "1px",
    backgroundColor: "white",
    transition: ".4s",
  },
  "& input:checked + .slider:before": { left: "-10px" },
});

const CourseChapterDeleteModal = styled(Modal)({
  height: "100%",
  display: "flex",
  width: "100%",
  alignItems: "center",
  justifyContent: "center",
});

const CourseChapterModalView = styled(Box)({
  height: "120px",
  borderRadius: "5px",
  width: "340px",
  padding: "10px 50px",
  outline: "none",
  display: "flex",
  backgroundColor: "#ffffff",
  margin: "20px",
  flexDirection: "column",
  boxShadow: "0px 0px 3px px transparent",
});

const CourseChapterDeleteText = styled(Box)({
  height: "37px",
  fontSize: "16px",
  width: "100%",
  fontWeight: 600,
});

const CourseChapterModalButton = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
});

const CourseChapterCancelButton = styled("button")({
  height: "40px",
  color: "#206575",
  width: "145px",
  border: "1px solid #206575",
  backgroundColor: "white",
  fontSize: "16px",
});

const CourseChapterDeleteButton = styled("button")({
  height: "45px",
  border: "0",
  width: "145px",
  fontSize: "16px",
  backgroundColor: "#DA1831",
  color: "white",
  fontWeight: 500,
});

const AddCourseChapterModal = styled(Modal)({
  width: "100%",
  height: "100%",
  alignItems: "center",
  justifyContent: "center",
  display: "flex",
});

const AddCourseChapterModalView = styled(Box)({
  width: "700px",
  height: "530px",
  borderRadius: "18px",
  backgroundColor: "#ffffff",
  outline: "none",
  margin: "20px",
  display: "flex",
  flexDirection: "column",
  boxShadow: "0px 0px 3px px transparent",
});

const AddCourseChapterModalInnerView = styled(Box)({
  overflowY: "auto",
  display: "flex",
  padding: "0 30px",
  flexDirection: "column",
});

const CourseChapterFieldBox = styled(Box)({
  alignItems: "center",
  display: "flex",
  justifyContent: "flex-start",
  "@media (max-width:1330px)": {
    flexDirection: "column",
    alignItems: "start",
  },
});

const CourseChapterFieldControlBox = styled(Box)({
  justifyContent: "flex-start",
  marginRight: "93px",
  alignItems: "center",
  margin: "10px 0",
  display: "flex",
  "& .input_txt": {
    textTransform: "capitalize",
    width: "160px",
    fontStyle: "normal",
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 400,
    color: "#192252",
    lineHeight: "24px",
  },
  "& .file_upload_box": {
    height: "44px",
    borderColor: "#D4D4D4",
    color: "#fff",
    justifyContent: "space-between",
    border: "1px solid black",
    display: "flex",
    width: "490px",
    borderRadius: "4px",
    alignItems: "center",
    "& .lable_txt": {
      fontSize: "12px",
      alignItems: "center",
      display: "flex",
      backgroundColor: "#3F6474",
      marginRight: "2px",
      borderRadius: "4px",
      justifyContent: "center",
      height: "90%",
      padding: "0px 30px",
    },
    "& .app_icon_txt": {
      marginLeft: "10px",
      wordSpacing: "-2px",
      fontSize: "13px",
      color: "#A9A9A9",
    },
  },
  "@media (max-width:875px)": {
    alignItems: "start",
    flexDirection: "column",
    "& .input_txt": {
      width: "100%",
      marginBottom: "8px",
    },
  },
});

const CourseChapterInputBox = styled(TextField)({
  width: "490px",
  "& .MuiOutlinedInput-input": { padding: "0px 14px" },
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": { borderColor: "#3F6474" },
  },
  "& .MuiInputBase-input": { height: "44px" },
});

export const CourseChapterheckBodButton = styled("label")({
  "& .slider": {
    borderRadius: "20px",
    bottom: "-1px",
    position: "absolute",
    top: 0,
    backgroundColor: "#ccc",
    cursor: "pointer",
    right: 0,
    transition: ".4s",
    left: 0,
    WebkitTransition: ".4s",
  },
  "& input:checked + .slider:before": { left: "-10px" },
  "&.switch": {
    width: "36px",
    position: "relative",
    marginTop: "5px",
    "& input": {
      opacity: 0,
      height: 0,
      width: 0,
    },
    height: "19px",
    display: "inline-block",
  },
  "& input:checked + .slider": { backgroundColor: "#708328" },
  "& .slider:before": {
    position: "absolute",
    height: "18px",
    WebkitTransition: ".4s",
    content: '""',
    left: "1px",
    backgroundColor: "white",
    borderRadius: "50%",
    transition: ".4s",
    bottom: "1px",
    width: "18px",
  },
});

const CourseChapterButtonBox = styled(Box)({
  justifyContent: "center",
  display: "flex",
  width: "100%",
  alignItems: "center",
  margin: "25px 0",
  "& .create_btn": {
    borderRadius: "0",
    color: "#fff",
    fontSize: "14px",
    backgroundColor: "#3f6474",
    boxShadow: "none",
    margin: "0px 10px",
    fontFamily: "Inter",
    textTransform: "capitalize",
    fontWeight: 700,
    lineHeight: "22px",
    height: "40px",
    fontStyle: "normal",
    width: "179px",
  },
  "& .reset_btn": {
    backgroundColor: "#fff",
    border: "1px solid #3F6474",
    width: "108px",
    padding: "10px 60px",
    fontSize: "14px",
    color: "#3f6474",
    borderRadius: "0",
    textTransform: "capitalize",
    fontFamily: "Inter",
    height: "40px",
    margin: "0px 10px",
    lineHeight: "22px",
    boxShadow: "none",
    fontStyle: "normal",
    fontWeight: 700,
  },
  "@media (max-width:460px)": {
    "& .create_btn": {
      height: "40px",
      padding: "5px 48px",
    },
    "& .reset_btn": {
      height: "40px",
      padding: "5px 50px",
    },
  },
  "@media (max-width:400px)": {
    "& .create_btn": { width: "100%" },
    "& .reset_btn": { marginBottom: "10px", width: "100%" },
    flexDirection: "column",
  },
});

const AddCourseChapterModalBox = styled(Box)({
  width: "100%",
  paddingTop: 10,
  justifyContent: "space-between",
  paddingBottom: 22,
  display: "flex",
});

const AddCourseChapterHeadingText = styled(Typography)({
  fontStyle: "normal",
  letterSpacing: "-0.12px",
  fontSize: "18px",
  fontFamily: "Inter",
  color: "#000",
  lineHeight: "26px",
  fontWeight: 700,
  padding: "10px 30px 0px 30px",
});

const SelectBox = styled(Select)({
  "&.MuiInputBase-root": {
    border: "1px solid lightgray",
    width: "490px",
    borderRadius: "4px",
  },
  "& .MuiNativeSelect-icon": {
    top: "calc(50% - 7px);",
    right: "6px",
  },
  "& .MuiInputBase-input": { padding: "10px 0px 10px 8px" },
  "&  .MuiSelect-icon": {
    right: "10px",
  },
});
// Customizable Area End
