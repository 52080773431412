Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.apiContentType = "application/json";
exports.addCategoryContentType = "multipart/form-data";
exports.exportCategoryContentType = "text/csv";
exports.getMethod = "GET";
exports.postMethod = "POST";
exports.deleteMethod = "DELETE";
exports.putMethod = "PUT";
exports.productAPiEndPoint = "catalogue/catalogues";
exports.coursesListApiEndpoint = "bx_block_profile/courses/all_courses";
exports.categoryAdminApiEndpoint = "bx_block_course/course_categories";
exports.subCategoryEndpoint = "bx_block_course/course_sub_category/course_sub_categories_list";
exports.childCategoryEndpoint = "bx_block_course/course_child_categories/list_course_child_categories";
exports.addSubCatEndpoint = "bx_block_course/course_sub_category";
exports.addChildCategoryEndpoint = "bx_block_course/course_child_categories";
exports.getCourseDetailApiEndpoint = "bx_block_profile/courses/";
exports.getBundleCourseDetailsApiEndPoint = "bx_block_profile/bundle_courses/"
exports.addToCartApiEndpoint = "bx_block_shopping_cart/add_to_cart";
exports.getRelatedCoursesApiEndpoint = "bx_block_profile/courses/related_course";
exports.getAllCoursesApiEndpoint = "bx_block_cfrazorpayintegration64/my_course?check_status=active";
exports.getTrendingCoursesWebApiEndPoint="bx_block_profile/courses/trending_courses";
exports.addToChartApiEndpoint = "bx_block_shopping_cart/add_to_cart";
exports.wishlistApiEndpoint = "bx_block_wishlist/add_wishlist";
exports.removeWishlistApiEndpoint = "bx_block_wishlist/remove_course_from_wishlist";
exports.courseValidityTitle = "Choose your Course Validity";
exports.cancelText = "Cancel";
exports.applyText = "Apply";
exports.copyText = "COPY";
exports.shareOnText = "Share on"
exports.validity2year = "2 Year Validity";
exports.validity1Year = "1 Year Validity";
exports.videoNotSupportedMsg = "Your browser does not support the video tag.";
exports.whatWelearnTitle = "What You Will Learn ?";
exports.chapter = "Chapter";
exports.leactureText = "Lectures";
exports.minutes = "Minutes";
exports.courseDescriptionTitle = "Course Description";
exports.getTestimonialsAverageRating = '/bx_block_profile/testimonials/average_rating?course_id='
exports.getTestimmonialList = '/bx_block_profile/testimonials/list_testimonials?course_id='
// Customizable Area End