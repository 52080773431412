export const headerImage=require("../assets/headerImage.svg");
export const headerImage2=require("../assets/headerImage2.png");
export const lecturesIcon=require("../assets/lecturesIcon.svg");
export const board=require("../assets/board.png");
export const board2=require("../assets/board2.png");
export const thumbnail=require("../assets/thumbnail.svg");
export const logo=require("../assets/logo.png");
export const Shape1 = require("../assets/Shape (1).svg");
export const Shape = require("../assets/Shape.svg");
export const Shape2 = require("../assets/Shape (2).svg");
export const Shape3 = require("../assets/Shape (3).svg");
export const Shape4 = require("../assets/Shape (4).svg");
export const Shape5 = require("../assets/Shape (5).svg");

export const facebook = require("../assets/facebook.svg");
export const graduation = require("../assets/graduation-cap.svg");
export const insta = require("../assets/instagram.svg");
export const telegram = require("../assets/telegram.svg");
export const whatsup = require("../assets/whatsup.svg");
export const OvalBlue = require("../assets/BlueShape.svg");
export const YellowCircles = require("../assets/YellowShape.svg");

export const googlePlay = require("../assets/5a902dbf7f96951c82922875.svg");
export const logoLeft = require("../assets/image_logo.svg");
export const logoRight = require("../assets/cropb2 copy.svg");
export const mail = require("../assets/envelope.svg");
export const tele = require("../assets/phone-disconnect.svg");
export const building = require("../assets/buildings.svg");
export const face = require("../assets/face.svg");
export const ins = require("../assets/ins.svg");
export const you = require("../assets/you.svg");

export const roket = require("../assets/image_logo (1).svg");
export const fourGrid = require("../assets/grid-four.svg");
export const avatar = require("../assets/user-circle.svg");
export const cart = require("../assets/shopping-cart.svg");
export const heart = require("../assets/heart.svg");
export const bell = require("../assets/bell.svg");

export const prev = require("../assets/prev.svg");
export const next = require("../assets/next.svg");
export const teaching = require("../assets/view_shapes.svg");
export const blueCircle = require("../assets/view_oval.svg");
export const playIcon = require("../assets/image_play.svg");
export const heartFill = require("../assets/heart-fill.svg");
export const image_star = require("../assets/image_star.svg");
export const Group3 = require("../assets/Group 3.svg");
export const copy = require("../assets/clipboard-text.svg");

export const related1 = require("../assets/related1.svg");
export const related2 = require("../assets/related2.svg");
export const appLogo = require("../assets/logo.png");

export const study = require("../assets/study.png");
export const courseDetailBg = require("../assets/bg.jpg");
export const myCourseBg = require("../assets/bg-course.jpg");
export const clockIcon = require("../assets/clock.png");
export const instagramIcon = require("../assets/instagram.png");
export const facebookIcon = require("../assets/facebook.png");
export const telegramICon = require("../assets/telegram.png");
export const whatsappIcon = require("../assets/whatsapp.png");
export const backArrowPage=require("../assets/backArrowPage.png");
export const forwardArrowPage=require("../assets/forwardArrowPage.png");
export const starUnselected=require("../assets/star_unselected.png");
export const starSelected=require("../assets/star_selected.png");






