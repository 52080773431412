import React from "react";
// Customizable Area Start
import {
  Box,
  AppBar,
  Typography,
  Button,
  FormControl,
  TextField,
  styled,
  createTheme,
  NativeSelect,
} from "@material-ui/core";
import { IoIosArrowDown } from "react-icons/io";
import MainLayout from "../../../components/src/MainLayout.web";
import NotificationAlertMessage from "../../../components/src/NotificationAlertMessage";
import { webStyles } from "./AdminCourses.web";
import CoursesSidebar from "./components/CoursesSidebar.web";
import { configJSON } from "./AdminCourseClassesController.web";
const drawerWidth = 250;
const closeDrawer = 0;

const theme = createTheme({
  typography: {
    allVariants: {
      fontFamily: "'Inter', monospace",
    },
    body1: {
      fontFamily: "'Inter', monospace",
    },
  },
});

const menuItems = [
  { 
    text: "Course Edit",
    path: configJSON.courseEditPath,
    active: true
  },
  { 
    text: "Course Chapter",
    path: configJSON.courseChapterPath,
    active: false 
  },
  { 
    text: "Course Classes",
    path: configJSON.courseClassesPath,
    active: false 
  },
  { 
    text: "Reviews & Ratings",
    path: configJSON.courseRatingsPath, 
    active: false 
  },
];

// Customizable Area End
import AdminCoursesController, {
  ChildCategoryDataType,
  InstructorDataType,
  LanguageDataType,
  Props,
} from "./AdminCoursesController.web";
import { redDelete, bluePlusIcon } from "./assets";
import Loader from "../../../components/src/Loader.web";

import RteTest from "./RteTest";
import { ProfilePicInput } from "../../../components/src/GenericInputs";

export default class AdminCourses extends AdminCoursesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.closemodal = this.closemodal.bind(this);
    // Customizable Area End
  }
  // Customizable Area Start
  closemodal() {
    this.oncloseAlert();
  }

  renderCategorySelect = () => {
    return (
      <Box className="child__box">
        <Typography className="box__label">Main Category*</Typography>
        <FormControl variant="outlined" className="box__input" fullWidth>
          <SelectBox
            fullWidth
            disableUnderline
            id="demo-customized-select-native"
            data-test-id="categorySelectTestId"
            onChange={(e: any) =>
              this.handleSelectChange(e.target.value, "categoryId", true)
            }
            onClick={() => this.handleSelectClick("categoryId")}
            IconComponent={IoIosArrowDown}
            value={this.state.categoryId}
          >
            <option aria-label="None" value="" disabled />
            {this.state.categoryData?.map((category: ChildCategoryDataType) => {
              return (
                <option key={category.id} value={category.id}>
                  {category.attributes.name}
                </option>
              );
            })}
          </SelectBox>
          <span style={webStyles.errorText}>{this.state.categoryErr}</span>
        </FormControl>
      </Box>
    );
  };

  renderSubCategorySelect = () => {
    return (
      <Box className="child__box">
        <Typography className="box__label">Sub Category*</Typography>
        <FormControl variant="outlined" className="box__input" fullWidth>
          <SelectBox
            id="demo-customized-select"
            fullWidth
            disableUnderline
            data-test-id="subCategorySelectTestId"
            IconComponent={IoIosArrowDown}
            inputProps={{
              id: "select-multiple-native",
            }}
            onChange={(e: any) =>
              this.handleSelectChange(e.target.value, "subCategoryId", true)
            }
            onClick={() => this.handleSelectClick("subCategoryId")}
            value={this.state.subCategoryId}
          >
            <option aria-label="None" value="" disabled />
            {this.state.subCategoryData?.map(
              (subCategory: ChildCategoryDataType) => {
                return (
                  <option key={subCategory.id} value={subCategory.id}>
                    {subCategory.attributes.name}
                  </option>
                );
              }
            )}
          </SelectBox>
          <span style={webStyles.errorText}>{this.state.SubCategoryErr}</span>
        </FormControl>
      </Box>
    );
  };

  renderChildCategorySelect = () => {
    return (
      <Box className="child__box">
        <Typography className="box__label">Child Category*</Typography>
        <FormControl variant="outlined" className="box__input" fullWidth>
          <SelectBox
            id="demo-customized-select"
            fullWidth
            disableUnderline
            data-test-id="childCatInputTestId"
            IconComponent={IoIosArrowDown}
            inputProps={{
              id: "select-multiple-native",
            }}
            onChange={(e: any) =>
              this.handleSelectChange(e.target.value, "childCategoryId")
            }
            onClick={() => this.handleSelectClick("childCategoryId")}
            value={this.state.childCategoryId}
          >
            <option aria-label="None" value="" disabled />
            {this.state.childCategoryData?.map(
              (childCategory: ChildCategoryDataType) => {
                return (
                  <option key={childCategory.id} value={childCategory.id}>
                    {childCategory.attributes.name}
                  </option>
                );
              }
            )}
          </SelectBox>
          <span style={webStyles.errorText}>{this.state.SubCategoryErr}</span>
        </FormControl>
      </Box>
    );
  };

  renderLanguageSelect = () => {
    return (
      <Box className="child__box">
        <Typography className="box__label">Language*</Typography>
        <FormControl variant="outlined" className="box__input" fullWidth>
          <SelectBox
            fullWidth
            disableUnderline
            id="demo-customized-select"
            IconComponent={IoIosArrowDown}
            inputProps={{
              id: "select-multiple-native",
            }}
            data-test-id="languageInputTestId"
            onChange={(e: any) =>
              this.handleSelectChange(e.target.value, "languageId")
            }
            value={this.state.languageId}
          >
            <option aria-label="None" value="" disabled />
            {this.state.languageData?.map((language: LanguageDataType) => {
              return (
                <option key={language.id} value={language.id}>
                  {language.attributes.language_name}
                </option>
              );
            })}
          </SelectBox>
          <span style={webStyles.errorText}>{this.state.languageErr}</span>
        </FormControl>
      </Box>
    );
  };

  renderInstructorSelect = () => {
    return (
      <Box className="child__box">
        <Typography className="box__label">Instructor*</Typography>
        <FormControl variant="outlined" className="box__input" fullWidth>
          <SelectBox
            id="demo-customized-select"
            fullWidth
            disableUnderline
            data-test-id="instructorInputTestId"
            IconComponent={IoIosArrowDown}
            inputProps={{
              id: "select-multiple-native",
            }}
            onChange={(e: any) =>
              this.handleSelectChange(e.target.value, "instructorId")
            }
            value={this.state.instructorId}
          >
            <option aria-label="None" value="" disabled />
            {this.state.instructorData?.map(
              (instructor: InstructorDataType) => {
                return (
                  <option key={instructor.id} value={instructor.id}>
                    {instructor.attributes.email}
                  </option>
                );
              }
            )}
          </SelectBox>
          <span style={webStyles.errorText}>{this.state.InstructorErr}</span>
        </FormControl>
      </Box>
    );
  };

  renderChooseTagSelect = () => {
    return (
      <Box className="child__box">
        <Typography className="box__label">Choose Tags*</Typography>
        <FormControl variant="outlined" className="box__input" fullWidth>
          <SelectBox
            id="demo-customized-select"
            fullWidth
            disableUnderline
            onClick={() => this.getTagList()}
            data-test-id="chooseInputTestId"
            IconComponent={IoIosArrowDown}
            inputProps={{
              id: "select-multiple-native",
            }}
            onChange={(e: any) =>
              this.handleSelectChange(e.target.value, "chooseTag")
            }
            value={this.state.chooseTag}
          >
            <option aria-label="None" value="" disabled />
            {this.state.choosTagList &&
                  this.state.choosTagList.length > 0 &&
                  this.state.choosTagList.map((item: any) => (
                    <option value={item}>{item}</option>
                  ))}
          </SelectBox>
          <span style={webStyles.errorText}>{this.state.InstructorErr}</span>
        </FormControl>
      </Box>
    );
  };

  renderInstituteSelect = () => {
    return (
      <Box className="child__box">
        <Typography className="box__label">Institute*</Typography>
        <FormControl variant="outlined" fullWidth className="box__input">
          <SelectBox
            id="demo-customized-select"
            fullWidth
            disableUnderline
            data-test-id="instituteInputTestId"
            IconComponent={IoIosArrowDown}
            inputProps={{
              id: "select-multiple-native",
            }}
            onChange={(e: any) =>
              this.handleSelectChange(e.target.value, "instituteId")
            }
            value={this.state.instituteId}
          >
            <option aria-label="None" value="" disabled />
            {this.state.instituteData?.map(
              (institute: ChildCategoryDataType) => {
                return (
                  <option key={institute.id} value={institute.id}>
                    {institute.attributes.name}
                  </option>
                );
              }
            )}
          </SelectBox>
          <span style={webStyles.errorText}>{this.state.instituteErr}</span>
        </FormControl>
      </Box>
    );
  };

  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <MainLayout
        data-test-id="mainLayoutEvent"
        backData={this.handleCoursesNavigation}
        isLoading={this.state.loader}
        title={this.state.isUpdate ? "Edit Course" : "Add Course"}
        isAdd={true}
        isEdit={true}
      >
        <NotificationAlertMessage
          alertType={this.state.alertType}
          msg={this.state.alertMsg}
          onClose={this.closemodal}
          isOpen={this.state.isAlert}
        />
        <CourseWrapper>
          {this.state.isUpdate && <CoursesSidebar data-test-id="coursesSidebar" menuItems={menuItems} onItemClick={this.handleSidebarItemClick} />}
          <main className="table_content">{this.addCourse()}</main>
        </CourseWrapper>
      </MainLayout>
      // Customizable Area End
    );
  }

  addCourse() {
    return (
      <div className="bundleCourseMainLayout">
        <Box sx={webStyles.adminLayout}>
          <TwoColBox>{this.renderCategorySelect()}</TwoColBox>
          <TwoColBox>{this.renderSubCategorySelect()}</TwoColBox>
          <TwoColBox>{this.renderChildCategorySelect()}</TwoColBox>

          <OneColBox>
            <Typography className="box__label">Main Course Name*</Typography>
            <FormControl variant="outlined" fullWidth className="box__input">
              <InputBox
                variant="outlined"
                fullWidth
                data-test-id="courseNameInputTestId"
                onChange={(e) =>
                  this.handleInputChange(e.target.value, "courseName")
                }
                value={this.state.courseName}
              />
              <span style={webStyles.errorText}>
                {this.state.courseNameErr}
              </span>
            </FormControl>
          </OneColBox>

          <OneColBox>
            <Typography
              className="box__label"
              style={{ alignSelf: "flex-start" }}
            >
              Short Detail
            </Typography>
            <FormControl 
              variant="outlined" 
              fullWidth 
              className="box__input"
              style={{ color: "#192252" }}
            >
              {!this.state.loader && (
                <RteTest
                  data-test-id="shortDetail"
                  onChange={(e) =>
                    this.handleInputChange(e, "shortDetail")
                  }
                  markup={this.state.shortDetail}
                  testId="shortDetail"
                />
              )}
              <span style={webStyles.errorText}>
                {this.state.shortDetailErr}
              </span>
            </FormControl>
          </OneColBox>

          <TwoColBox>
            {this.renderInstituteSelect()}
            {this.renderLanguageSelect()}
          </TwoColBox>

          <TwoColBox>{this.renderInstructorSelect()}
            {this.renderChooseTagSelect()}
          </TwoColBox>

          <OneColBox>
            <Typography className="box__label">Details</Typography>
            <FormControl
              variant="outlined"
              className="box__input"
              fullWidth
              style={{ color: "#192252" }}
            >
              {!this.state.loader && (
                <RteTest
                  onChange={(e) => this.handleInputChange(e, "fullDescription")}
                  markup={this.state.fullDescription}
                  testId="rte"
                />
              )}
            </FormControl>
          </OneColBox>

          <OneColBox>
            <Typography className="box__label">What You’ll Learn</Typography>
            <FormControl variant="outlined" className="box__input" fullWidth>
              <InputBox
                variant="outlined"
                fullWidth
                data-test-id="whatLearnTestId"
                onChange={(e) =>
                  this.handleSelectChange(e.target.value, "learn")
                }
                value={this.state.learn}
              />
            </FormControl>

            <Button
              variant="outlined"
              startIcon={
                <img src={bluePlusIcon} style={{ color: "#1F6575" }} />
              }
              data-test-id="handleAddLearningId"
              onClick={this.handleAddWhoYoullLearn}
              style={webStyles.AddMoreText}
            >
              Add More
            </Button>
          </OneColBox>

          {this.state.additionalLearning.map((field: any, index: any) => (
            <OneColBox>
              <Typography className="box__label">What You'll Learn</Typography>
              <FormControl variant="outlined" className="box__input" fullWidth>
                <InputBox
                  data-test-id={"additionalFieldTestId"}
                  variant="outlined"
                  fullWidth
                  value={field}
                  onChange={(e) => this.handleAdditionalFieldChange(e, index)}
                />
              </FormControl>

              <Button
                data-test-id="addLearnMore"
                style={webStyles.AddMoreText}
                variant="outlined"
                startIcon={
                  <img src={bluePlusIcon} style={{ color: "#1F6575" }} />
                }
                onClick={this.handleAddWhoYoullLearn}
              >
                Add More
              </Button>
              <Button
                data-test-id="deleteLearnMoreTestId"
                onClick={() => this.handleDeleteAdditionalLearning(index)}
                variant="outlined"
                className="deleteBtn"
                startIcon={<img src={redDelete} />}
                style={{ ...webStyles.DeleteIconBtn, alignSelf: "start" }}
              ></Button>
            </OneColBox>
          ))}

          <ThreeColBox>
            <Box className="child__box">
              <Typography className="box__label">First Year Price*</Typography>
              <FormControl variant="outlined" className="box__input" fullWidth>
                <InputBox
                  variant="outlined"
                  fullWidth
                  onChange={(e) =>
                    this.handleInputChange(e.target.value, "FYP", true)
                  }
                  data-test-id="firstYearPriceTestId"
                  value={this.state.FYP}
                />
                <span style={webStyles.errorText}>{this.state.FYPErr}</span>
              </FormControl>
            </Box>

            <Box className="child__box">
              <Typography className="box__label">Second Year Price</Typography>
              <FormControl variant="outlined" className="box__input" fullWidth>
                <InputBox
                  variant="outlined"
                  data-test-id="SYP"
                  fullWidth
                  onChange={(e) =>
                    this.handleInputChange(e.target.value, "SYP", true)
                  }
                  value={this.state.SYP}
                />
                <span style={webStyles.errorText}>{this.state.SYPErr}</span>
              </FormControl>
            </Box>

            <Box className="child__box">
              <Typography className="box__label">Discounted Price*</Typography>
              <FormControl variant="outlined" className="box__input" fullWidth>
                <InputBox
                  variant="outlined"
                  data-test-id="Discount"
                  fullWidth
                  onChange={(e) =>
                    this.handleInputChange(e.target.value, "Discount", true)
                  }
                  value={this.state.Discount}
                />
                <span style={webStyles.errorText}>
                  {this.state.DiscountErr}
                </span>
              </FormControl>
            </Box>
          </ThreeColBox>

          <OneColBox>
            <Typography className="box__label">Thumbnail Image</Typography>
            <FormControl variant="outlined" className="box__input" fullWidth>
              {this.state.selectedFilePreviewThumbnail ? (
                <ProfilePicInput
                  onChange={(e) => this.handleFileChange(e)}
                  onRemove={() => this.handleDeleteFile()}
                  data-test-id="fileChangeTestId"
                  name="imgupload"
                  previewThumbnail={this.state.selectedFilePreviewThumbnail}
                />
              ) : (
                <label
                  htmlFor="fileInput"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "10px",
                    border: "2px dashed #ccc",
                    cursor: "pointer",
                    width: "98%",
                    borderRadius: "5px",
                    margin: "0 auto",
                    backgroundColor: "#fff",
                    color: "black",
                  }}
                >
                  <Typography>+ UPLOAD THUMBNAIL IMAGE</Typography>
                  <input
                    type="file"
                    id="fileInput"
                    data-test-id="fileChangeTestId"
                    style={{ display: "none" }}
                    onChange={(e) => this.handleFileChange(e)}
                    multiple
                  />
                </label>
              )}

              <span style={webStyles.errorText}>{this.state.fileErr}</span>
            </FormControl>
          </OneColBox>

          <OneColBox>
            <Typography className="box__label">Preview Video URL</Typography>
            <FormControl variant="outlined" className="box__input" fullWidth>
              <InputBox
                variant="outlined"
                data-test-id="videoPreview"
                fullWidth
                onChange={(e) =>
                  this.handleInputChange(e.target.value, "videoPreview")
                }
                value={this.state.videoPreview}
              />
              <span style={webStyles.errorText}>
                {this.state.videoPreviewErr}
              </span>
            </FormControl>
          </OneColBox>

          <ButtonBox>
            {this.state.isUpdate ? (
              <Button
                variant="contained"
                className="reset_btn"
                onClick={this.handleCoursesNavigation}
              >
                Cancel
              </Button>
            ) : (
              <Button
                data-test-id="handleReset"
                variant="contained"
                className="reset_btn"
                onClick={() => this.handleCourseDataReset()}
              >
                Reset
              </Button>
            )}
            {this.state.isUpdate ? (
              <Button
                onClick={() => this.handlePostCourse(false)}
                data-test-id="updateButtonTestId"
                disabled={this.state.loader}
                variant="contained"
                className="create_btn"
              >
                Update
              </Button>
            ) : (
              <Button
                data-test-id="createButtonTestId"
                onClick={() => this.handlePostCourse(true)}
                disabled={this.state.loader}
                variant="contained"
                className="create_btn"
              >
                Create
              </Button>
            )}
          </ButtonBox>
        </Box>
        {this.props.children}
      </div>
    );
  }
}
// Customizable Area Start
const InputBox = styled(TextField)({
  "& .MuiOutlinedInput-input": { padding: "0px 14px" },
  "& .MuiInputBase-input": { height: "40px" },
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": { borderColor: "#3F6474" },
  },
});

const colProps: any = {
  display: "inline-flex",
  columnGap: "2rem",
  borderBottom: "1px solid rgba(189, 188, 188, 0.314)",
  flexWrap: "wrap",
  width: "100%",
  justifyContent: "space-between",
  "& .child__box": {
    display: "flex",
    paddingBottom: 20,
    columnGap: "2rem",
    justifyContent: "space-between",
    "& .box__label": {
      color: "#192252",
      fontSize: "0.875rem",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "24px",
      display: "flex",
      alignItems: "center",
      minWidth: "150px",
    },
    "& .box__input": {
      "& .MuiInputBase-root": {
        background: "white",
      },
    },
  },

  "@media (max-width:1024px)": {
    display: "flex",
    flexDirection: "column",
    "& .child__box": {
      flexDirection: "column",
      "& .box__input": {
        width: "100% !important",
      },
    },
  },
};

const ThreeColBox = styled(Box)({
  ...colProps,
  "& .child__box": {
    ...colProps["& .child__box"],
    flex: "0 0 30%",
  },
});

const TwoColBox = styled(Box)({
  ...colProps,
  "& .child__box": {
    ...colProps["& .child__box"],
    flex: "0 0 47%",
  },
});
const OneColBox = styled(Box)({
  borderBottom: "1px solid rgba(189, 188, 188, 0.314)",
  columnGap: "2rem",
  display: "inline-flex",
  width: "100%",
  paddingBottom: 20,
  justifyContent: "space-between",
  "& .deleteBtn": {
    "& .MuiButton-startIcon": {
      marginLeft: 0,
      marginRight: 0,
    },
  },
  "& .box__label": {
    color: "#192252",
    fontSize: "0.875rem",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "24px",
    display: "flex",
    alignItems: "center",
    minWidth: "150px",
  },
  "& .box__input": {
    "& .MuiInputBase-root": {
      background: "white",
    },
  },
  "@media (max-width:1024px)": {
    display: "flex",
    flexDirection: "column",
    "& .box__input": {
      width: "100% !important",
    },
  },
});

export const AddNavBar = styled(AppBar)({
  backgroundColor: "#fff",
  "&.MuiPaper-elevation4": {
    boxShadow: "none",
  },
  marginLeft: "250px",
  "& .table_content": {
    height: "80vh",
    fontFamily: "Inter !important",
    "@media (max-width:950px)": {
      overflowX: "scroll",
      padding: "10px",
    },
  },
  "& .nav_container": {
    flexDirection: "column",
    justifyContent: "space-between",
    width: "100%",
    padding: "41px 0px 38px 22px",
    display: "flex",
  },
  "& .nav_txt_box": {
    display: "flex",
    "& .drower_menu": {
      display: "none",
      width: "35px",
      "@media (max-width:970px)": { display: "block" },
      height: "35px",
      color: "black",
    },
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    color: "#000",
    "& .avtar_box": {
      alignItems: "center",
      display: "flex",
      justifyContent: "space-between",
      width: "90px",
    },
    "& .main_heading": {
      fontFamily: "Inter",
      color: "#000",
      fontSize: "1.375rem",
      lineHeight: "32px",
      letterSpacing: "-0.12px",
      fontStyle: "normal",
      fontWeight: 700,
    },
  },
  "& .search_box": {
    "& .btn_box": {
      justifyContent: "space-between",
      display: "flex",
      "& .btns": {
        fontSize: "0.875rem",
        textTransform: "capitalize",
        marginLeft: "10px",
        color: "#fff",
        padding: "9px 22px",
        backgroundColor: "#206575",
        borderRadius: "0",
        "@media (max-width:970px)": {
          marginLeft: "0",
          padding: "6px 12px",
          marginTop: "20px",
          marginRight: "10px",
        },
      },
    },
    alignItems: "center",
    justifyContent: "space-between",
    display: "flex",
    "@media (max-width:970px)": {
      alignItems: "start",
      flexDirection: "column",
      justifyContent: "flex-start",
    },
    "& .search_input": {
      height: "45px",
      "@media (max-width:970px)": { width: "100%" },
      width: "350px",
      borderRadius: "2px",
      "& .icon_color": { color: "#b2b2b2" },
      "&.Mui-focused fieldset": { borderColor: "#206575" },
    },
  },
});

const ButtonBox = styled(Box)({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  margin: "25px 0",
  alignItems: "center",
  "& .create_btn": {
    backgroundColor: "#3f6474",
    borderRadius: "0",
    padding: "10px 58px",
    textTransform: "capitalize",
    color: "#fff",
    fontSize: "0.875rem",
    boxShadow: "none",
    margin: "0px 10px",
    width: "195px",
    fontFamily: "Inter",
    height: "52px",
    fontWeight: 700,
    lineHeight: "24px",
    fontStyle: "normal",
  },
  "& .reset_btn": {
    backgroundColor: "#fff",
    borderRadius: "0",
    textTransform: "capitalize",
    border: "1px solid #3F6474",
    fontSize: "0.875rem",
    padding: "10px 60px",
    width: "195px",
    color: "#3f6474",
    margin: "0px 10px",
    height: "52px",
    fontFamily: "Inter",
    fontStyle: "normal",
    boxShadow: "none",
    lineHeight: "24px",
    fontWeight: 700,
  },
  "@media (max-width:460px)": {
    "& .create_btn": {
      height: "40px",
      padding: "5px 48px",
    },
    "& .reset_btn": {
      height: "40px",
      padding: "5px 50px",
    },
  },
  "@media (max-width:400px)": {
    flexDirection: "column",
    "& .create_btn": { width: "100%" },
    "& .reset_btn": { marginBottom: "10px", width: "100%" },
  },
});
const SelectBox = styled(NativeSelect)({
  "&.MuiInputBase-root": {
    border: "1px solid lightgray",
    height: "40px",
    borderRadius: "4px",
  },
  "& .MuiNativeSelect-icon": {
    top: "calc(50% - 7px);",
    right: "6px",
  },
  "& .MuiInputBase-input": { paddingLeft: "14px" },
});

const MultiLineTextField = styled(TextField)({
  "& .MuiInputBase-input": { height: "86px" },
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": { borderColor: "#3F6474" },
  },
});

export const Wrapper = styled(Box)({
  display: "flex", 
  overflow: "hidden",
  "& .table_content": {
    width: "100%",
    marginTop: '-10px',
  },
  "& .bundleCourseMainLayout": {
    width: "90%", 
    margin: "0 auto",
  },
});

export const CourseWrapper = styled(Box)({
  display: "flex", 
  "& .table_content": {
    height: "100vh !important",
    width: "100%",
    padding:'10px'
  },
  "& .bundleCourseMainLayout": {
    width: "90%", 
    margin: "0 0 50px 50px",
  },
});
// Customizable Area End
