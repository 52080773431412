import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { getStorageData, setStorageData,removeStorageData } from "../../../framework/src/Utilities";
import { Color } from "@material-ui/lab/Alert";
import { elementsPerPage } from "../../../components/src/MainLayout.web";
import storage from "../../../framework/src/StorageProvider";

export interface ResponseType {
  meta: {
    page: string;
    total_courses: number;
    courses?: "Record not found.";
    message?: string;
  };
}

interface ResponseErrors {
  errors: {
    token: string;
  }[];
}

export interface CoursesResponseType extends ResponseType {
  data: Array<CourseType>;
  errors: Array<any>;
  error: string;
}

export interface ChildCategoryResponseType extends ResponseType {
  data: ChildCategoryDataType[];
}

export interface ResponseDataType {
  id: string;
  type: string;
}

export interface LanguageDataType extends ResponseDataType {
  attributes: {
    language_name: string;
  };
}

export interface InstructorDataType extends ResponseDataType {
  attributes: {
    email: string;
  };
}

export interface ChildCategoryDataType extends ResponseDataType {
  attributes: {
    name: string;
    course_category_id: number;
    course_sub_category_id: number;
    color: string;
    status: boolean;
    total_courses: number;
    student_count: number;
    course_category: {
      id: number;
      name: string;
    };
    course_sub_category: {
      id: number;
      name: string;
    };
    mobile_icon: string;
    web_icon: string;
  };
}

export interface APIPayload {
  contentType?: string;
  method: string;
  endPoint: string;
  apiBody?: object;
  body?: object;
  token?: string;
  type?: string;
}
export interface ValidResponseType {
  message: string;
  data: object;
  scheduled: object;
}
export interface CourseType extends ResponseType {
  attributes: {
    course_name: string;
    short_description: string;
    full_description: string;
    account_id: number;
    first_year_price: number;
    second_year_price: number;
    discounted_price: number;
    preview_video_url: string;
    status: boolean;
    trending: boolean;
    what_learn: string[];
    course_sub_category: {
      id: any;
      name: string;
      course_category_id: number;
      created_at: string;
      updated_at: string;
      status: boolean;
      total_courses: null;
      total_subjects: null;
    };
    course_child_category: {
      id: any;
      course_category_id: any;
      course_sub_category_id: any;
      name: string;
      total_courses: null;
      total_students: null;
      status: boolean;
      color: string;
    };
    student_count: null;
    course_category: {
      id: any;
      name: string;
    };
    instructor_name: string;
    language_name: string;
    total_content: any;
  };
}

// DATA VALIDATION FORM SUBMIT
interface FormFields {
  instituteId: string;
  courseName: string;
  categoryId: string;
  subCategoryId: string;
  childCategoryId: string;
  instructorId: string;
  FYP: string;
  selectedFileName: string;
  chooseTag:string;
}

const fieldNames: { [K in keyof FormFields]: string } = {
  instituteId: "Institute",
  courseName: "Course Name",
  categoryId: "Main Category",
  subCategoryId: "Sub category",
  childCategoryId: "Child category",
  instructorId: "Instructor",
  FYP: "First year price",
  selectedFileName: "Thumbnail image",
  chooseTag:"Choose Tags"
};

// Customizable Area End
export const configJSON = require("./config");
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  isChecked: boolean[];
  token: string;
  isHeaderChecked: boolean;
  pageCount: number;
  currentPageCount: number;
  isDrawer: boolean;
  searchedText: string;
  setfullWidth: boolean;
  loader: boolean;
  perPage: number;
  openDeleteModal: boolean;
  deleteId: string | number;
  isAlert: boolean;
  alertType: Color;
  alertMsg: string;
  adminCourse: CoursesResponseType;
  DeleteModal: boolean;
  isSelectedId: any;
  selectedDate: string;
  selectedFile: any;
  selectedFilePreviewThumbnail: string;
  fileErr: string;
  selectedFileName: string;
  videoPreview: string;
  videoPreviewErr: string;
  fullDescription: string;
  fullDescriptionErr: string;
  isUpdate: boolean;
  categoryErr: any;
  SubCategoryErr: any;
  childCategoryErr: any;
  courseName: any;
  courseNameErr: any;
  shortDetail: any;
  shortDetailErr: any;
  instituteErr: any;
  languageErr: any;
  InstructorErr: any;
  learn: any;
  learnErr: any;
  FYP: any;
  FYPErr: any;
  SYP: any;
  SYPErr: any;
  Discount: any;
  DiscountErr: any;
  date: any;
  courseId: string;
  additionalLearning: any;

  role: string;
  isAddCourse: boolean;
  isSeletedDate1: any;
  isSelectedDate2: any;

  categoryId: string;
  categoryData: any;
  categoryDataDone: boolean;
  childCategoryId: string;
  childCategoryData: any[];
  childCategoryDataDone: boolean;
  subCategoryId: any;
  subCategoryData: any;
  subCategoryDataDone: boolean;
  instituteData: any[];
  instituteId: string;
  languageId: string;
  languageData: any[];
  instructorData: any[];
  instructorId: string;
  currentSortState: { order: string; field: string };
  columnSort: string;
  chooseTag:string;
  choosTagList:string[]
  // Customizable Area End
}
interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}
export default class AdminCoursesController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getAdminCourseInstituteListApiCallId: string = "";
  getAdminCourseLanguageListApiCallId: string = "";
  getAdminCourseInstructorListApiCallId: string = "";
  getAdminCourseChildCategoryListApiCallId: string = "";
  getAdminCourseCategoryListApiCallId: string = "";
  getAdminCourseSubCategoryListApiCallId: string = "";
  showCourseApiCallId: string = "";
  postAddCourseApiCallId: string = "";
  editCoursepiCallId: string = "";
  toggleAdminCourseListOptionApiCallId: string = "";

  deletelangApiCallId: string = "";
  getCoursesApiCallID: string = "";
  deleteCourseAPICallId: string = "";
  addStudentAPICallID: string = "";
  getTagListApiCallId:string=""

  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.NavigationIdMessage),
      // Customizable Area End
    ];
    this.state = {
      // Customizable Area Start
      courseId: "",
      isUpdate: false,
      isChecked: [],
      token: "",
      isHeaderChecked: false,
      pageCount: 0,
      currentPageCount: 1,
      isDrawer: true,
      searchedText: "",
      setfullWidth: true,
      loader: true,
      perPage: elementsPerPage,
      categoryErr: "",
      SubCategoryErr: "",
      childCategoryErr: "",
      courseName: "",
      courseNameErr: "",
      shortDetail: "",
      shortDetailErr: "",
      instituteErr: "",
      languageErr: "",
      InstructorErr: "",
      videoPreview: "",
      learn: "",
      learnErr: "",
      FYP: "",
      FYPErr: "",
      SYP: "",
      SYPErr: "",
      Discount: "",
      DiscountErr: "",
      openDeleteModal: false,
      deleteId: "",
      isAlert: false,
      alertType: "success",
      alertMsg: "",
      DeleteModal: false,
      isSelectedId: 0,
      fullDescription: "",
      fullDescriptionErr: "",
      videoPreviewErr: "",
      additionalLearning: [],

      categoryId: "",
      categoryData: [],
      categoryDataDone: false,
      subCategoryId: "",
      subCategoryDataDone: false,
      subCategoryData: [],
      childCategoryData: [],
      childCategoryDataDone: false,
      childCategoryId: "",
      instituteData: [],
      instituteId: "",
      languageId: "",
      languageData: [],
      instructorData: [],
      instructorId: "",

      selectedDate: "",
      selectedFile: null,
      fileErr: "",
      selectedFilePreviewThumbnail: "",
      selectedFileName: "",

      role: "Student",

      isSeletedDate1: "",
      isSelectedDate2: "",

      date: new Date(),

      isAddCourse: false,
      adminCourse: {
        data: [],
        meta: {
          page: "",
          total_courses: 0,
        },
        errors: [{ token: "" }],
        error: "",
      },
      currentSortState: { order: "desc", field: "id_sort" },
      columnSort: "",
      chooseTag:'',
      choosTagList:[]
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }
  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const courseResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (!courseResponseJson.errors) {
        this.apiSuccessCallBack(apiRequestCallId, courseResponseJson);
      } else {
        this.parseErrors(courseResponseJson);
      }
    } else if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      let courseData = message.getData(
        getName(MessageEnum.NavigationIdMessage)
      );
      if (courseData) {
        this.setState({ courseId: courseData.ScreenTest });
        this.handleShowCourse(courseData.ScreenTest);
      }
    }
    // Customizable Area
  }
  // Customizable Area Start

  parseErrors = async (responseJson: any) => {
    if (Object.keys(responseJson.errors[0])[0] === "token") {
      this.navigationLoginScreen();
    } else {
      this.setState({loader:false})
      responseJson.errors.forEach((error: { [key: string]: string }) => {
        const key = Object.keys(error)[0];
        const errorMessage = error[key];
    
        switch (key) {
          case "discounted_price":
            this.setState({ DiscountErr: errorMessage });
            break;
          case "second_year_price":
            this.setState({ SYPErr: errorMessage });
            break;
          case "preview_video_url":
            this.setState({ videoPreviewErr: errorMessage });
            break;
          case "courses":
            this.setState({ DiscountErr: errorMessage });
        }
      });
      this.parseApiErrorResponse(responseJson);
    }
  };

  handleDate1Change = (e: any) => {
    this.setState({ isSeletedDate1: e.target.value });
  };

  deleteStudent(id: any) {
    this.setState(
      { isSelectedId: id },

      () => {
        this.setState({ openDeleteModal: true });
      }
    );
  }

  apiSuccessCallBack = (apiRequestCallId: string, responseJson: any) => {
    switch (apiRequestCallId) {
      case this.getCoursesApiCallID:
        this.handleGetCoursesListResponse(responseJson);
        break;
      case this.deleteCourseAPICallId:
        this.handleDeleteAdminCourseListResponse(responseJson);
        break;
      case this.getAdminCourseCategoryListApiCallId:
        this.handleSetStateCourseData(
          responseJson,
          "categoryData",
          "categoryId"
        );
        break;
      case this.getAdminCourseSubCategoryListApiCallId:
        this.handleSetStateCourseData(
          responseJson,
          "subCategoryData",
          "subCategoryId"
        );
        break;
      case this.getAdminCourseChildCategoryListApiCallId:
        this.handleSetStateCourseData(
          responseJson,
          "childCategoryData",
          "childCategoryId"
        );
        break;
      case this.getAdminCourseInstituteListApiCallId:
        this.handleSetStateCourseData(
          responseJson,
          "instituteData",
          "instituteId"
        );
        break;
      case this.getAdminCourseLanguageListApiCallId:
        this.handleSetStateCourseData(
          responseJson,
          "languageData",
          "languageId"
        );
        break;
      case this.getAdminCourseInstructorListApiCallId:
        this.handleSetStateCourseData(
          responseJson,
          "instructorData",
          "instructorId"
        );
        break;
      case this.toggleAdminCourseListOptionApiCallId:
        this.handleToggleOptionsResponse(responseJson);
        break;
      case this.showCourseApiCallId:
        this.handleShowCourseResponse(responseJson);
        break;
      case this.postAddCourseApiCallId:
        this.handleAddAdminCourseResponse(responseJson);
        break;
      case this.editCoursepiCallId:
        this.handleAddAdminCourseResponse(responseJson);
        break;
      case this.getTagListApiCallId:
        this.handleTagListResponse(responseJson)
      default:
        break;
    }
  };
  handleTagListResponse = (resJson: any) => {
    const tagValues = Object.values(resJson.tag_types) as any;
    this.setState({
      loader: false,
      choosTagList: tagValues
    });
  };

  handleAddAdminCourseResponse = (responseJson:any) => {
    storage.remove('EditId');storage.remove('IsUpdate')
    let alertMsg, alertType: any;

    alertMsg = "Course Created Successfully";
    alertType = "success";
    this.handleCourseDataReset();
    this.setState({ isAlert: true, alertMsg, alertType, loader: false });
    this.handleCoursesNavigation();
  };

  handleCoursesNavigation = () => {
    storage.remove('EditId'); storage.remove('IsUpdate')
    const msg: Message = new Message(
      getName(MessageEnum.NavigationAdminCoursesMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  };

  async componentDidMount() {
    await this.getUserToken();
    const id=await getStorageData('EditId')
    const isUpdate=await getStorageData('IsUpdate')
    if(isUpdate){
      this.setState({isUpdate:true})
      this.handleShowCourse(id)
    }
    await this.getInstituteList();
    await this.getInstructorsList();
    await this.getLanguagesList();
    this.getTagList()
    await this.getCoursesList(
      this.state.currentPageCount,
      this.state.perPage,
      this.state.columnSort
    );
  }

  onPageChange = (event: any, value: any) => {
    this.setState(
      {
        currentPageCount: Number(value),
      },
      () => {
        this.getCoursesList(
          this.state.currentPageCount,
          this.state.perPage,
          this.state.columnSort
        );
      }
    );
  };

  handleShowCourse = async (id: string | number) => {
    this.setState({ loader: true });
    this.showCourseApiCallId = this.apiCall({
      contentType: configJSON.courseChaptersApiContentType,
      method: configJSON.httpGetType,
      endPoint: `bx_block_profile/courses/${id}`,
    });
  };

  handleEditPropsCourses =async (id: string | number, courseName: string) => {
    await setStorageData('EditId',id)
    await setStorageData('EditCourseName',courseName)
    await setStorageData('IsUpdate',true)
    const msg: Message = new Message(
      getName(MessageEnum.NavigationAddAdminCourseMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationIdMessage), { ScreenTest: id });
    this.send(msg);
  };

  handleAddCourseNavigation = () => {
    removeStorageData('EditId'); removeStorageData('IsUpdate')
    const msg: Message = new Message(
      getName(MessageEnum.NavigationAddAdminCourseMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  };

  getCoursesList = async (
    pageNo: number,
    per_page: number,
    sorting: string
  ) => {
    const isDateSearch =
      !!(this.state.isSeletedDate1 && this.state.isSelectedDate2) &&
      `&start_date=${this.state.isSeletedDate1}&end_date=${this.state.isSelectedDate2}`;
    const isSearchText =
      !!(this.state.searchedText && this.state.searchedText.length > 0) &&
      `&search_params=${this.state.searchedText}`;

    this.setState({ loader: true });
    this.getCoursesApiCallID = this.apiCall({
      contentType: configJSON.courseChaptersApiContentType,
      method: configJSON.httpGetType,
      endPoint: `${
        configJSON.getCoursesByName
      }?page=${pageNo}&per_page=${per_page}${isDateSearch || ""}${
        isSearchText || ""
      }${sorting || ""}`,
    });
  };

  handleSort(field: string) {
    const sortParams: Record<string, string> = {
      ["S.No"]: "id_sort",
      ["Main Course Name"]: "course_name_sort",
      Status: "status_sort",
    };
    let fieldSort = "";
    const sortOrder =
      this.state.currentSortState.order === "asc" ? "desc" : "asc";

    if (sortParams.hasOwnProperty(field)) {
      fieldSort = `&${sortParams[field]}=${sortOrder}`;
    }
    this.toggleSortState(field, fieldSort);
    this.getCoursesList(
      this.state.currentPageCount,
      this.state.perPage,
      fieldSort
    );
  }

  toggleSortState = (field: string, columnSort: string) => {
    this.setState({
      columnSort,
      currentSortState: {
        field,
        order: this.state.currentSortState.order === "asc" ? "desc" : "asc",
      },
    });
  };

  handleToggleOptionsResponse = (responseJson: ResponseType) => {
    this.getCoursesList(
      this.state.currentPageCount,
      this.state.perPage,
      this.state.columnSort
    );
    this.setState({
      isAlert: true,
      alertMsg:
        responseJson?.meta?.message ||
        "Course status/trending change successfully",
      alertType: "success",
    });
  };

  handleToggleOptionsList = (
    isStatus: boolean,
    value: boolean,
    id: string | number,
    index: number
  ) => {
    const key = isStatus ? "status" : "trending";
    const endPoint =
      (isStatus
        ? configJSON.statusApiEndPoint
        : configJSON.trendingUpdateAPIEndPoint) + id;

    let clone = [...this.state.adminCourse.data];
    clone[index].attributes[key] = !value;

    this.setState({
      loader: true,
      adminCourse: { ...this.state.adminCourse, data: clone },
    });
    this.toggleAdminCourseListOptionApiCallId = this.apiCall({
      endPoint,
      contentType: configJSON.courseClassesApiContentType,
      method: configJSON.httpPutType,
      type: "",
      body: {
        change_status: !value,
      },
    });
  };

  handleDeleteApiCall = async (id: string | number) => {
    this.setState({ loader: true });
    this.deleteCourseAPICallId = this.apiCall({
      contentType: configJSON.courseChaptersApiContentType,
      method: configJSON.httpDeleteType,
      endPoint: `${configJSON.deleteCourseApiEndPoint}/${id}`,
    });
  };

  handleDate2Change = (e: any) => {
    this.setState(
      { isSelectedDate2: e.target.value, currentPageCount: 1 },
      () =>
        this.getCoursesList(
          this.state.currentPageCount,
          this.state.perPage,
          this.state.columnSort
        )
    );
  };

  handleGetCoursesListResponse = (response: CoursesResponseType) => {
    if (response.meta?.courses === "Record not found.") {
      this.setState({
        isAlert: true,
        alertMsg: response.meta.courses,
        alertType: "info",
        loader: false,
        pageCount: 0,
        adminCourse: {
          data: [],
          meta: {
            page: "",
            total_courses: 0,
          },
          errors: [{ token: "" }],
          error: "",
        },
        isChecked: [],
      });
    } else if (
      response?.errors &&
      response.errors.length > 0 &&
      response.errors[0]?.token == configJSON.tokenExpiredMessage
    ) {
      this.navigationLoginScreen();
    } else {
      let count = response.meta.total_courses / this.state.perPage;
      count = Math.ceil(count);
      this.setState({
        adminCourse: response,
        isChecked: Array(
          this.state.adminCourse.data ? this.state.adminCourse.data.length : 0
        ).fill(false),
        pageCount: count,
        loader: false,
      });
    }
  };
  handleDeleteAdminCourseListResponse = (response: CoursesResponseType) => {
    if (response.error) {
      this.setState({
        openDeleteModal: false,
        deleteId: "",
        loader: false,
        isAlert: true,
        alertMsg: response.error,
        alertType: "error",
      });
    } else {
      this.setState(
        {
          openDeleteModal: false,
          deleteId: "",
          loader: false,
          isAlert: true,
          alertMsg: "Course was successfully deleted.",
          alertType: "success",
        },
        () => {
          this.getCoursesList(
            this.state.currentPageCount,
            this.state.perPage,
            this.state.columnSort
          );
        }
      );
    }
  };

  getUserToken = async () => {
    const token = await getStorageData("loginToken");
    this.setState({ token: token });
  };

  apiCall = (data: APIPayload) => {
    let { contentType, method, endPoint, body, type } = data;
    let requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      "Content-Type": contentType,
      token: this.state.token,
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        type === "" ? JSON.stringify(body) : body
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };
  handleCourseSearch = (e: { target: { value: string } }) => {
    let searchVal = e.target.value;
    this.setState({ searchedText: searchVal, currentPageCount: 1 }, () => {
      this.getCoursesList(
        this.state.currentPageCount,
        this.state.perPage,
        this.state.columnSort
      );
    });
  };

  handleCheckBoxes = (index: number) => {
    this.setState((prevState) => {
      const isChecked = [...prevState.isChecked];
      isChecked[index] = !isChecked[index];
      const isHeaderChecked = isChecked.every((value) => value);
      return {
        isChecked,
        isHeaderChecked,
      };
    });
  };
  oncloseAlert = () => {
    this.setState({
      isAlert: false,
    });
  };

  handleCheckboxChange = () => {
    this.setState((previousState) => {
      const isHeaderChecked = !previousState.isHeaderChecked;
      const isChecked = Array(this.state.adminCourse.data.length).fill(
        isHeaderChecked
      );
      return {
        isHeaderChecked,
        isChecked,
      };
    });
  };

  handleCloseDeleteModal = () => {
    this.setState({
      openDeleteModal: false,
      deleteId: "",
    });
  };

  handleDeleteFile() {
    this.setState({
      selectedFile: null,
      selectedFileName: "",
      selectedFilePreviewThumbnail: "",
      fileErr: "",
    });
  }

  handleFileChange = (event: any) => {
    const files = event.target.files;

    if (files && files.length > 0) {
      const file = files[0];
      const fileName = file.name;
      const fileSize = file.size;

      const allowedFormats = ["image/png", "image/jpeg", "image/jpg"];
      if (!allowedFormats.includes(file.type)) {
        this.setState({
          fileErr:
            "Invalid file format. Please upload a PNG, JPG, or JPEG file.",
          selectedFile: null,
          selectedFileName: "",
          selectedFilePreviewThumbnail: "",
        });
      } else if (fileSize > configJSON.maxFileSize) {
        this.setState({
          fileErr: "File size exceeds 2MB. Please choose a smaller file.",
          selectedFile: null,
          selectedFileName: "",
          selectedFilePreviewThumbnail: "",
        });
      } else {
        const imageUrl = URL.createObjectURL(file);
        this.setState({
          selectedFile: file,
          selectedFileName: fileName,
          fileErr: "",
          selectedFilePreviewThumbnail: imageUrl,
        });
      }
    } else {
      this.setState({
        fileErr: "No file is selected",
        selectedFile: null,
        selectedFileName: "",
        selectedFilePreviewThumbnail: "",
      });
    }
  };

  handleCourseDataReset() {
    this.setState({
      selectedFileName: "",
      fileErr: "",
      selectedFile: "",
      date: "",
      courseName: "",
      shortDetail: "",
      learn: [],
      additionalLearning: [],
      FYP: "",
      SYP: "",
      Discount: "",
      videoPreview: "",
      fullDescription: "",
      instructorId: "",
      categoryId: "",
      subCategoryId: "",
      childCategoryId: "",
      languageId: "",
      instituteId: "",
    });
  }

  //  ---- COURSE CREATION AND EDITION ----
  handleShowCourseResponse = (response: any) => {
    this.setState({ loader: true });
    const { attributes } = response.data;
    let learn = "",
      additionalLearning = [];
    if (attributes.what_learn.length > 0) {
      const [learnResponse, ...additionalResponse] = attributes.what_learn;
      learn = learnResponse;
      if (additionalResponse.length > 0)
        additionalLearning = additionalResponse;
    }

    this.setState({
      loader: false,
      courseName: attributes.course_name,
      shortDetail: attributes.short_description || "",
      learn,
      FYP: attributes.first_year_price,
      SYP: attributes.second_year_price || "",
      Discount: attributes.discounted_price || "",
      selectedFileName: attributes.course_thumbnail || "",
      selectedFilePreviewThumbnail: attributes.course_thumbnail || "",
      categoryData: [
        {
          ...attributes.course_category,
          attributes: { name: attributes.course_category.name },
        },
      ],
      subCategoryData: [
        {
          id: attributes.course_sub_category,
          attributes: { name: attributes.course_sub_category.name },
        },
      ],
      childCategoryData: [
        {
          id: attributes.course_child_category,
          attributes: { name: attributes.course_child_category.name },
        },
      ],
      videoPreview: attributes.preview_video_url || "",
      fullDescription: attributes.full_description || "",
      categoryId: attributes.course_category.id,
      subCategoryId: attributes.course_sub_category.id,
      childCategoryId: attributes.course_child_category.id,
      chooseTag:attributes.average_rating.tag,
      isUpdate: true,
      instituteId:
        attributes.institute_id || attributes.average_rating.institute_id || "",
      instructorId: attributes.account_id,
      additionalLearning,
      languageId:
        attributes.average_rating.language_options_language_id ||
        this.getIdFromArray(
          this.state.languageData,
          attributes.language_name,
          "language_name"
        ),
    });
  };

  getIdFromArray = (array: any[], name: string, attributeName: string) => {
    const findId = array.find(
      (value) => value.attributes[attributeName] === name
    );
    return findId ? findId.id : "";
  };

  handlePostCourse = async (isCreateMode: boolean) => {
    const {
      categoryId,
      courseName,
      shortDetail,
      learn,
      FYP,
      SYP,
      Discount,
      selectedFile,
      selectedFileName,
      videoPreview,
      fullDescription,
      subCategoryId,
      childCategoryId,
      instituteId,
      instructorId,
      languageId,
      additionalLearning,chooseTag
    } = this.state;
    this.setState({ loader: true });

    const blankField = this.getBlankField({
      instituteId,
      courseName,
      categoryId,
      subCategoryId,
      childCategoryId,
      instructorId,
      selectedFileName,
      FYP,
      chooseTag
    });
    if (blankField) {
      this.setState({
        isAlert: true,
        alertMsg: `${blankField} cannot be blank`,
        alertType: "error",
        loader: false,
      });
    } else {
      const formdata = new FormData();
      formdata.append("courses[course_category_id]", categoryId);
      formdata.append("courses[course_sub_category_id]", subCategoryId);
      formdata.append("courses[course_child_category_id]", childCategoryId);
      formdata.append("courses[course_name]", courseName);
      formdata.append("courses[short_description]", shortDetail);
      formdata.append("courses[account_id]", instructorId);
      formdata.append("courses[full_description]", fullDescription);
      formdata.append("courses[first_year_price]", FYP);
      formdata.append("courses[second_year_price]", SYP);
      formdata.append("courses[preview_video_url]", videoPreview);
      formdata.append("courses[discounted_price]", Discount);
      formdata.append("courses[institute_id]", instituteId);
      formdata.append("courses[language_options_language_id]", languageId);
      formdata.append("courses[tag]", this.state.chooseTag);
      formdata.append(
        "courses[what_learn]",
        "," + [learn, ...additionalLearning].toString() + ","
      );
      if (selectedFile)
        formdata.append(
          "courses[course_thumbnail]",
          selectedFile,
          selectedFileName
        );

      if (isCreateMode) {
        this.postAddCourseApiCallId = this.apiCall({
          method: configJSON.httpPostType,
          endPoint: "bx_block_profile/courses",
          body: formdata,
          type: "formdata",
        });
      } else {
        this.editCoursepiCallId = this.apiCall({
          method: configJSON.httpPutType,
          endPoint: `bx_block_profile/courses/${this.state.courseId}`,
          body: formdata,
          type: "formdata",
        });
      }
    }
  };

  handleAddWhoYoullLearn = () => {
    this.setState((prevState) => ({
      additionalLearning: [...prevState.additionalLearning, ""],
    }));
  };

  handleDeleteAdditionalLearning = (index: number) => {
    this.setState((prevState) => {
      const updatedFields = [...prevState.additionalLearning];
      updatedFields.splice(index, 1);
      return {
        additionalLearning: updatedFields,
      };
    });
  };

  handleAdditionalFieldChange = (e: any, index: number) => {
    const { value } = e.target;
    this.setState((prevState) => {
      const updatedFields = [...prevState.additionalLearning];
      updatedFields[index] = value;
      return {
        additionalLearning: updatedFields,
      };
    });
  };

  getBlankField = (fields: FormFields) => {
    for (let field in fields) {
      if (!fields[field as keyof FormFields]) {
        return fieldNames[field as keyof FormFields];
      }
    }
    return null;
  };

  getCourseCategoryData = async () => {
    this.setState({ loader: true });
    this.getAdminCourseCategoryListApiCallId = this.apiCall({
      contentType: configJSON.categoryApiContentType,
      method: configJSON.httpGetType,
      endPoint: configJSON.courseCategoryAPIListEndPoint,
    });
  };
  getTagList() {
    this.setState({ loader: true });
    this.getTagListApiCallId = this.apiCall({
      method: configJSON.httpGetType,
      contentType: configJSON.categoryApiContentType,
      endPoint: `bx_block_profile/bundle_courses/tag_types`
    });
  }
  handleShowSubCategoryList = async (id: string | number) => {
    this.setState({ loader: true });
    this.getAdminCourseSubCategoryListApiCallId = this.apiCall({
      contentType: configJSON.categoryApiContentType,
      method: configJSON.httpGetType,
      endPoint: `${configJSON.getSubCategoriesAPIEndpoint}?course_category_id=${id}`,
    });
  };

  handleShowChildCategoryList = async (
    categoryId: string | number,
    subCatId: string | number
  ) => {
    this.setState({ loader: true });
    this.getAdminCourseChildCategoryListApiCallId = this.apiCall({
      contentType: configJSON.categoryApiContentType,
      method: configJSON.httpGetType,
      endPoint: `${configJSON.getChildCategoriesAPIEndpoint}?course_category_id=${categoryId}&course_sub_category_id=${subCatId}`,
    });
  };

  getInstituteList = async () => {
    this.setState({ loader: true });
    this.getAdminCourseInstituteListApiCallId = this.apiCall({
      contentType: configJSON.categoryApiContentType,
      method: configJSON.httpGetType,
      endPoint: configJSON.getInstitutesListAPIEndpoint,
    });
  };

  getLanguagesList = async () => {
    this.setState({ loader: true });
    this.getAdminCourseLanguageListApiCallId = this.apiCall({
      contentType: configJSON.categoryApiContentType,
      method: configJSON.httpGetType,
      endPoint: configJSON.getLanguagesListAPIEndpoint,
    });
  };

  getInstructorsList = async () => {
    this.setState({ loader: true });
    this.getAdminCourseInstructorListApiCallId = this.apiCall({
      contentType: configJSON.categoryApiContentType,
      method: configJSON.httpGetType,
      endPoint: configJSON.getInstructorsListAPIEndpoint,
    });
  };

  handleSetStateCourseData = (
    response: ChildCategoryResponseType,
    stateName: string,
    valueId: string
  ) => {
    const x: any = this.state;
    const a = x[valueId];
    let y =
      (!a && a !== 0) ||
      !(response.data && response.data.find((v) => `${v.id}` === `${a}`))
        ? ""
        : a;

    const updatedState: any = {
      [stateName]: response.data,
      loader: false,
      [valueId]: y,
      ...(["categoryData", "subCategoryData", "childCategoryData"].includes(
        stateName
      )
        ? { [`${stateName}Done`]: true }
        : {}),
    };

    if (y !== a && stateName === "categoryData") {
      updatedState.subCategoryDataDone = false;
      updatedState.childCategoryDataDone = false;
    }
    if (stateName === "subCategoryData") {
      updatedState.childCategoryDataDone = false;
    }
    this.setState(updatedState, () => {
      if (y !== a && stateName === "categoryData") {
        this.handleShowSubCategoryList(y);
      }
      if (stateName === "subCategoryData") {
        this.handleShowChildCategoryList(this.state.categoryId, y);
      }
    });
  };

  handleSelectChange = (
    value: string,
    inputName: string,
    callback?: boolean
  ) => {
    const updatedState: any = { [inputName]: value };
    let callbackFn = undefined;
    if (callback) {
      if (inputName === "subCategoryId")
        callbackFn = async () =>
          await this.handleShowChildCategoryList(
            this.state.categoryId,
            this.state.subCategoryId
          );
      if (inputName === "categoryId")
        callbackFn = async () => {
          await this.handleShowSubCategoryList(this.state.categoryId);
        };
    }
    this.setState(updatedState, callbackFn);
  };

  handleSelectClick = (selectName: string) => {
    const state = this.state;
    switch (selectName) {
      case "subCategoryId":
        if (!state.subCategoryDataDone)
          this.handleShowSubCategoryList(state.categoryId);
        break;
      case "categoryId":
        if (!state.categoryDataDone) this.getCourseCategoryData();
        break;
      case "childCategoryId":
        if (!state.childCategoryDataDone)
          this.handleShowChildCategoryList(
            this.state.categoryId,
            this.state.subCategoryId
          );
        break;
    }
  };

  handleInputChange = (value: string, inputName: string, isNumber = false) => {
    if (isNumber) {
      if (!/^(?=.{0,10}$)[0-9]*\.?[0-9]*$/.test(value)) return;
    }
    if (inputName === "courseName") {
      if (/^[^a-zA-Z0-9]/.test(value)) {
        this.setState({ courseNameErr: "First character cannot be a space or special character" });
        return;
      }
      if (/^[0-9]/.test(value)) {
        this.setState({ courseNameErr: "First letter cannot be numeric" });
        return;
      }
        if (value.length > 30) {
        this.setState({ courseNameErr: "Course name cannot be more than 30 characters" });
        return;
      }
        this.setState({ courseNameErr: "" });
    }
    if(inputName=='shortDetail'){
      if(value.length>100){
        this.setState({shortDetailErr:"Short Detail cannot be more than 100 characters"})
        return
      }
      this.setState({shortDetailErr:''})
    }
    if(inputName=='videoPreview'){this.setState({videoPreviewErr:''})}
    if(inputName=='SYP'){this.setState({SYPErr:''})}
    if(inputName=='Discount'){this.setState({DiscountErr:''})}
    const updatedState: any = { [inputName]: value };
    this.setState(updatedState);
  };

  navigationLoginScreen = () => {
    alert(configJSON.sessionExpireMsg);
    const messageLogin: Message = new Message(
      getName(MessageEnum.NavigationEmailLogInMessage)
    );
    messageLogin.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(messageLogin);
  };

  handleSidebarItemClick = (route: string) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), route);
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };
  // Customizable Area End
}
