import React, { Component } from "react";
import { List, ListItem, ListItemText, Typography, Divider, Box } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";

// Styled components
const DrawerContainer = styled(Box)({
  width: 203,
  flexShrink: 0,
  backgroundColor: "#FFEFE7",
  height:'100vh'
});

const Title = styled(Typography)({
  padding: "16px",
  fontWeight: "bold",
  color: "#000000"
});

const ActiveItem = styled(ListItem)({
  color: "#ff6f00",
  fontWeight: "bold",
  borderRight: "4px solid #FD7101",
  paddingLeft: "28px",
});

const Item = styled(ListItem)({
  paddingLeft: "28px",
});

const ListItemTextLabel = styled(ListItemText)({
  color: "#000000",
});

interface MenuItem {
  text: string;
  path: string;
  active: boolean;
}

interface SidebarProps {
  menuItems: MenuItem[];
  onItemClick?: (path: string) => void;
}

class CoursesSidebar extends Component<SidebarProps> {
  handleItemClick = (path: string) => {
    const { onItemClick } = this.props;
    if (onItemClick) {
      onItemClick(path);
    }
  };

  render() {
    const { menuItems } = this.props;

    return (
      <DrawerContainer>
        <Title variant="h6" data-test-id="title">Courses</Title>
        <Divider />
        <List>
          {menuItems.map((item) =>
            item.active ? (
              <ActiveItem
                button
                key={item.text}
                data-test-id="activeTab"
                onClick={() => this.handleItemClick(item.path)}
              >
                <ListItemTextLabel data-test-id="activeListItem" primary={item.text} style={{ color: "#FD7101"}}/>
              </ActiveItem>
            ) : (
              <Item button key={item.text} data-test-id="tab" onClick={() => this.handleItemClick(item.path)}>
                <ListItemTextLabel primary={item.text} />
              </Item>
            )
          )}
        </List>
      </DrawerContainer>
    );
  }
}

export default CoursesSidebar;
