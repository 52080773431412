export const playIcon=require("../assets/playIcon.png")
export const joinOnlineImg=require("../assets/joinOnlineImg.png")
export const addFriend1=require("../assets/addFriend1.png")
export const addFriend2=require("../assets/addFriend2.png")
export const addFriend3=require("../assets/addFriend3.png")
export const forwardArrow=require("../assets/forwardArrow.png")
export const arrowRight=require("../assets/arrowRight.png")
export const backArrowPage=require("../assets/backArrowPage.png")
export const forwardArrowPage=require("../assets/forwardArrowPage.png")
export const dot=require("../assets/dot.png")
export const heart=require("../assets/heart.png")
export const viewLine=require("../assets/viewLine.png")
export const star=require("../assets/star.png")
export const rupeesIcon=require("../assets/rupeesIcon.png")
export const heartRed=require("../assets/heartRed.png")
export const chapter=require("../assets/chapter.png")
export const lecture=require("../assets/lecture.png")
export const time=require("../assets/time.png")
export const followers=require("../assets/followers.png")
export const courses=require("../assets/courses.png")
export const enrolled=require("../assets/enrolled.png")
export const teachers=require("../assets/teachers.png")
export const line=require("../assets/line.png")
export const invertedCommaStart=require("../assets/invertedCommaStart.png")
export const invertedCommaEnd=require("../assets/invertedCommaEnd.png")
export const refer=require("../assets/refer.png")
export const dotCarouselFirst=require("../assets/dotCorouselFirst.png")
export const caretDown=require("../assets/caretDown.png")
export const avtarSmall=require("../assets/avtarSmall.png")
export const avtarBig=require("../assets/avtarBig.png")
export const backPaginationArrow=require("../assets/backPaginationArrow.png")
export const forwardPaginationArrow=require("../assets/forwardPaginationArrow.png")
export const study=require("../assets/study.png")
export const studentReviewBgrd=require("../assets/studentReviewBgrd.png")
export const favFolder=require("../assets/favFolder.png")
export const laptopLastImg=require("../assets/laptopLastImg.png")
export const youtube=require("../assets/youtube.png")
export const book=require("../assets/book.png")
export const demoView=require("../assets/demoView.png")
export const verticalLine=require("../assets/verticalLine.png")
export const dashedLine=require("../assets/dashedLine.png")
export const checkCircle=require("../assets/checkCircle.png")
export const coursesIcon=require("../assets/coursesIcon.png")
export const chseExpressLogo = require("../assets/logo_1.svg");
export const chseExpressLogo2 = require("../assets/logo_2.svg");
export const googlePlayIcon = require("../assets/googlePlayStore.svg");
export const facebookIcon = require("../assets/130774468nJqfLg04s3ZTS9fiQmtLBZ.svg");
export const instagramIcon = require("../assets/130774469nJqfLg04s3ZTS9fiQmtLBZ.svg");
export const youtubeIcon = require("../assets/130774470nJqfLg04s3ZTS9fiQmtLBZ.svg");
export const addressIcon = require("../assets/addressIcon.svg");
export const phoneIcon = require("../assets/phoneIcon.svg");
export const emailIcon = require("../assets/emailIcon.svg");
export const web_BG_Visibility = require('../assets/Web_BG_Visibility.jpg');
export const reviewIdeaLamp = require('../assets/casual-life-3d-idea-lamp.png');
export const orangePlanetWithDisk = require('../assets/casual-life-3d-orange-planet-with-disk.png')
export const studentArrowGroup = require('../assets/Group.png')