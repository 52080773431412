// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";
import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import VideoLibrary from "../../blocks/VideoLibrary/src/VideoLibrary";
import CarouselDisplay from "../../blocks/carouseldisplay/src/CarouselDisplay";
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import OfflineBrowsing from "../../blocks/OfflineBrowsing/src/OfflineBrowsing";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import RolesPermissions2 from "../../blocks/RolesPermissions2/src/RolesPermissions2";
import Videos from "../../blocks/videos/src/Videos";
import MyCash from "../../blocks/couponcodegenerator/src/MyCash.web";
import MyCourses from "../../blocks/catalogue/src/MyCourse.web";
import MyCoursesDetail from "../../blocks/catalogue/src/MyCourseDetail.web";
import CourseDetails from "../../blocks/catalogue/src/CourseDetail.web";
import Couponcodegenerator from "../../blocks/couponcodegenerator/src/CouponCodegeneratorPage.web";
import AddCouponcodegenerator from "../../blocks/couponcodegenerator/src/AddCouponcodegenerator.web";
import Vouchercodegenerator from "../../blocks/couponcodegenerator/src/Vouchercodegenerator.web";
import AddVouchercodegenerator from "../../blocks/couponcodegenerator/src/AddVouchercodegenerator.web";
import FlatDiscount from "../../blocks/couponcodegenerator/src/FlatDiscount.web";
import AddFlatDiscount from "../../blocks/couponcodegenerator/src/AddFlatDiscount.web";
import Referral from "../../blocks/couponcodegenerator/src/Referral.web";
import PaymentAdmin2 from "../../blocks/PaymentAdmin2/src/PaymentAdmin2";
import CfRazorpayIntegration64 from "../../blocks/CfRazorpayIntegration64/src/CfRazorpayIntegration64";
import PushNotifications from "../../blocks/pushnotifications/src/PushNotifications.web";
import AddPushNotifications from "../../blocks/pushnotifications/src/AddPushNotifications.web";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import Referrals from "../../blocks/Referrals/src/Referrals.web";
import AdminConsole3 from "../../blocks/AdminConsole3/src/AdminConsole3";
import Settings5 from "../../blocks/Settings5/src/Settings5";
import UserProfileBasicBlock from "../../blocks/user-profile-basic/src/UserProfileBasicBlock";
import CfCaptureUuidOfDevice from "../../blocks/CfCaptureUuidOfDevice/src/CfCaptureUuidOfDevice";
import BulkUploading from "../../blocks/BulkUploading/src/BulkUploading";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories.web";
import ContentManagement from "../../blocks/ContentManagement/src/ContentManagement.web";
import AdminCourses from "../../blocks/ContentManagement/src/AdminCourses.web";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import TermsAndConditions from "../../blocks/TermsAndConditions/src/TermsAndConditions.web";
import AddTermsAndConditions from "../../blocks/TermsAndConditions/src/AddTermsAndConditions.web";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import Contactus from "../../blocks/contactus/src/Contactus.web";
import ContactusWeb from "../../blocks/contactus/src/ContactUsWeb.web";
import AddContactus from "../../blocks/contactus/src/AddContactus.web";
import Catalogue from "../../blocks/catalogue/src/Catalogue.web";
import CourseList from "../../blocks/catalogue/src/CourseList.web";
import EducationalUserProfile from "../../blocks/educational-user-profile/src/EducationalUserProfile.web";
import Dashboard from "../../blocks/dashboard/src/Dashboard.web";
import Splashscreen from "../../blocks/splashscreen/src/Splashscreen";
import ImageThumbnail from "../../blocks/ImageThumbnail/src/ImageThumbnail";
import ElasticSearch from "../../blocks/ElasticSearch/src/ElasticSearch";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock.web";
import LandingPage from "../../blocks/landingpage/src/LandingPage.web";
import Wishlist from "../../blocks/wishlist/src/Wishlistweb.web";
import EmailNotifications from "../../blocks/EmailNotifications/src/EmailNotifications";
import Settings from "../../blocks/Settings/src/Settings";
import AdminConsole2 from "../../blocks/AdminConsole2/src/AdminConsole2";
import PaymentAdmin from "../../blocks/PaymentAdmin/src/PaymentAdmin";
import TermsConditions from "../../blocks/termsconditions/src/TermsConditions.web";
import TermsConditionsDetail from "../../blocks/termsconditions/src/TermsConditionsDetail";
import TermsConditionsUsers from "../../blocks/termsconditions/src/TermsConditionsUsers";
import RolesPermissions from "../../blocks/RolesPermissions/src/RolesPermissions";
import EmailNotifications2 from "../../blocks/EmailNotifications2/src/EmailNotifications2";
import InstructorUserProfile from "../../blocks/educational-user-profile/src/InstructorUserProfile.web"
import PrivacyPolicy from "../../blocks/email-account-registration/src/PrivacyPolicy.web";
import courseDetails from "../../blocks/catalogue/src/CourseDetails.web";
import AdminProfile from "../../blocks/educational-user-profile/src/AdminProfile.web"
import CategoryAdmin from "../../blocks/catalogue/src/CategoryAdmin.web";
import AddCategory from "../../blocks/catalogue/src/AddCategory.web";
import SubCategoryAdmin from "../../blocks/catalogue/src/SubCategoryAdmin.web";
import AddSubCategory from "../../blocks/catalogue/src/AddSubCategory.web";
import ChildCategory from "../../blocks/catalogue/src/ChildCategory.web";
import AddChildCategory from "../../blocks/catalogue/src/AddChildCategory.web";
import BundleCourse from "../../blocks/categoriessubcategories/src/BundleCourse.web"
import Login from "../../blocks/email-account-login/src/Login.web"
import AddBundleCourse from "../../blocks/categoriessubcategories/src/AddBundleCourse.web"
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration.web"

import CustomReport from '../../blocks/baselinereporting/src/CustomReports.web';
import Banner from '../../blocks/user-profile-basic/src/Banner.web';
import AddBanner from '../../blocks/user-profile-basic/src/AddBanner.web';
import OrderManagement from "../../blocks/PaymentAdmin/src/OrderManagement.web";
import PaymentManagement from "../../blocks/PaymentAdmin/src/PaymentManagement.web";
import RazorpayTransactions from "../../blocks/PaymentAdmin/src/RazorpayTransactions.web";

import AdminCategory from '../../blocks/categoriessubcategories/src/AdminCategory.web';
import AddAdminCategory from '../../blocks/categoriessubcategories/src/AddAdminCategory.web';
import AdminSubCategory from '../../blocks/categoriessubcategories/src/AdminSubCategory.web';
import AddAdminSubCategory from '../../blocks/categoriessubcategories/src/AddAdminSubCategory.web';
import AdminChildCategory from '../../blocks/categoriessubcategories/src/AdminChildCategory.web';
import AddAdminChildCategory from '../../blocks/categoriessubcategories/src/AddAdminChildCategory.web';
import AdminInstitutes from '../../blocks/categoriessubcategories/src/AdminInstitutes.web';
import AdminCourseClasses from "../../blocks/ContentManagement/src/AdminCourseClasses.web";
import AddAdminCourse from "../../blocks/ContentManagement/src/AddAdminCourses.web";
import AdminReviewsAndRatings from "../../blocks/ContentManagement/src/AdminReviewsAndRatings.web";
import CourseReviewsAndRatings from "../../blocks/ContentManagement/src/CourseReviewsAndRatings.web";
import AdminStateList from "../../blocks/user-profile-basic/src/AdminStateList.web";
import AdminDistrictList from "../../blocks/user-profile-basic/src/AdminDistrictList.web";
import AddAdminDistrict from "../../blocks/user-profile-basic/src/AddAdminDistrict.web";
import CancellationPolicy from "../../blocks/TermsAndConditions/src/CancellationPolicy.web";
import AddCancellationPolicy from "../../blocks/TermsAndConditions/src/AddCancellationPolicy.web";

import Testimonials from "../../blocks/user-profile-basic/src/Testimonials.web";
import AddAdminState from "../../blocks/user-profile-basic/src/AddAdminState.web";
import Achievements from "../../blocks/user-profile-basic/src/Achievements.web";
import DailyReports from "../../blocks/baselinereporting/src/DailyReports.web";
import Footer from "../../blocks/user-profile-basic/src/Footer.web";
import UserProfile from "../../blocks/user-profile-basic/src/UserProfile.web";
import AboutUs from "../../blocks/TermsAndConditions/src/AboutUs.web";
import AddAboutUs from "../../blocks/TermsAndConditions/src/AddAboutUs.web";
import Faq from "../../blocks/TermsAndConditions/src/Faq.web";
import AddFaq from "../../blocks/TermsAndConditions/src/AddFaq.web";
import RefundPolicy from "../../blocks/TermsAndConditions/src/RefundPolicy.web";
import AddRefundPolicy from "../../blocks/TermsAndConditions/src/AddRefundPolicy.web";

import AdminLanguage from '../../blocks/categoriessubcategories/src/AdminLanguage.web';
import AdminPrivacyPolicy from "../../blocks/TermsAndConditions/src/AdminPrivacyPolicy.web";
import AddAdminPrivacyPolicy from "../../blocks/TermsAndConditions/src/AddAdminPrivacyPolicy.web";
import HowItWork from "../../blocks/TermsAndConditions/src/HowItWork.web";
import AddHowItWork from "../../blocks/TermsAndConditions/src/AddHowItWork.web";
import AddHeaderBanner from "../../blocks/user-profile-basic/src/AddHeaderBanner.web";
import HeaderBanner from "../../blocks/user-profile-basic/src/HeaderBanner.web";
import AddEmailNotifications from "../../blocks/EmailNotifications/src/AddEmailNotifications.web"
import ShoppingCartOrders from "../../blocks/shoppingcart/src/ShoppingCartOrders.web";
import TermsAndConditionsWeb from "../../blocks/TermsAndConditions/src/TermsAndConditionsWeb.web";
import FaqsWeb from "../../blocks/TermsAndConditions/src/FaqsWeb.web";
import PurchaseHistory from "../../blocks/PaymentAdmin/src/PurchaseHistory.web";
import { getStorageData } from "framework/src/Utilities";

const routeMap = {
  Signup: {
    component: EmailAccountRegistration,
    path: "/Signup",
    exact: true,
    allowedRoles: []
  },

  CourseDetails: {
    component: courseDetails,
    path: "/course-detail",
    allowedRoles: ["Student", ""]
  },

  Login: {
    component: Login,
    path: "/Login",
    exact: true,
    allowedRoles: [""]
  },

  Achievements: {
    component: Achievements,
    path: "/Achievements",
    exact: true,
    allowedRoles: ["Super Admin"]
  },

  DailyReports: {
    component: DailyReports,
    path: "/DailyReport",
    exact: true,
    allowedRoles: ["Super Admin"]
  },
  
  AboutUs: {
    component: AboutUs,
    path: "/AboutUs",
    exact: true,
    allowedRoles: ["Super Admin"]
  },

  AddAboutUs: {
    component: AddAboutUs,
    path: "/AddAboutUs",
    exact: true,
    allowedRoles: ["Super Admin"]
  },

  Faq: {
    component: Faq,
    path: "/Faq",
    exact: true,
    allowedRoles: ["Super Admin"]
  },

  AddFaq: {
    component: AddFaq,
    path: "/AddFaq",
    exact: true,
    allowedRoles: ["Super Admin"]
  },

  RefundPolicy: {
    component: RefundPolicy,
    path: "/RefundPolicy",
    exact: true,
    allowedRoles: ["Super Admin"]
  },

  AddRefundPolicy: {
    component: AddRefundPolicy,
    path: "/AddRefundPolicy",
    exact: true,
    allowedRoles: ["Super Admin"]
  },

  TermsAndConditions: {
    component: TermsAndConditions,
    path: "/TermsAndConditions",
    exact: true,
    allowedRoles: ["Super Admin"]
  },
  HelpCenter: {
    component: TermsAndConditionsWeb,
    path: "/HelpCenter/:navigationBarTitleText",
    exact: true
  },
  FaqsWeb: {
    component: FaqsWeb,
    path: "/FaqsWeb"
  },
  AddTermsAndConditions: {
    component: AddTermsAndConditions,
    path: "/AddTermsAndConditions",
    exact: true,
    allowedRoles: ["Super Admin"]
  },

  Footer: {
    component: Footer,
    path: "/Footer",
    exact: true,
    allowedRoles: ["Super Admin"]
  },
  UserProfile: {
    component: UserProfile,
    path: "/UserProfile",
    exact: true,
    allowedRoles: ["Student"]
  },
  AdminUserProfile: {
    component: UserProfile,
    path: "/AdminUserProfile",
    exact: true,
    allowedRoles: ["Super Admin"]
  },
  CustomReport: {
    component: CustomReport,
    path: "/CustomReport",
    exact: true,
    allowedRoles: ["Super Admin"]
  },
  Banner: {
    component: Banner,
    path: "/Banner",
    exact: true,
    allowedRoles: ["Super Admin"]
  },
  AddBanner: {
    component: AddBanner,
    path: "/AddBanner",
    exact: true,
    allowedRoles: ["Super Admin"]
  },
  CancellationPolicy: {
    component: CancellationPolicy,
    path: "/CancellationPolicy",
    exact: true,
    allowedRoles: ["Super Admin"]
  },
  AddCancellationPolicy: {
    component: AddCancellationPolicy,
    path: "/AddCancellationPolicy",
    exact: true,
    allowedRoles: ["Super Admin"]
  },
  AdminLanguage: {
    component: AdminLanguage,
    path: "/AdminLanguage",
    exact: true,
    allowedRoles: ["Super Admin"]
  },
  PrivacyPolicy: {
    component: PrivacyPolicy,
    path: "/privacy-policy",
    exact: true,
    allowedRoles: []
  },
  CategoryAdmin: {
    component: CategoryAdmin,
    path: "/CategoryAdmin",
    exact: true,
    allowedRoles: ["Super Admin"]
  },
  HowItWorks: {
    component: HowItWork,
    path: "/HowItWorks",
    exact: true,
    allowedRoles: ["Super Admin"]
  },
  AddHowItWork: {
    component: AddHowItWork,
    path: "/AddHowItWork",
    exact: true,
    allowedRoles: ["Super Admin"]
  },
  AddCategory: {
    component: AddCategory,
    path: "/AddCategory",
    exact: true,
    allowedRoles: ["Super Admin"]
  },
  SubCategoryAdmin: {
    component: SubCategoryAdmin,
    path: "/SubCategoryAdmin",
    exact: true,
    allowedRoles: ["Super Admin"]
  },
  AddSubCategory: {
    component: AddSubCategory,
    path: "/AddSubCategory",
    exact: true,
    allowedRoles: ["Super Admin"]
  },
  InstructorUserProfile: {
    component: InstructorUserProfile,
    path: "/InstructorUserProfile",
    exact: true,
    allowedRoles: ["Super Admin"]
  },
  ChildCategory: {
    component: ChildCategory,
    path: "/ChildCategory",
    exact: true,
    allowedRoles: ["Super Admin"]
  },
  AddChildCategory: {
    component: AddChildCategory,
    path: "/AddChildCategory",
    exact: true,
    allowedRoles: ["Super Admin"]
  },
  Settings: {
    component: Settings,
    path: "/Settings",
    allowedRoles: ["Super Admin"]
  },
  AdminConsole2: {
    component: AdminConsole2,
    path: "/AdminConsole2",
    allowedRoles: []
  },
  PaymentAdmin: {
    component: PaymentAdmin,
    path: "/PaymentAdmin",
    allowedRoles: []
  },
  OrderManagement: {
    component: OrderManagement,
    path: "/OrderManagement",
    allowedRoles: ["Super Admin"]
  },
  PaymentManagement: {
    component: PaymentManagement,
    path: "/PaymentManagement",
    allowedRoles: ["Super Admin"]
  },
  RazorpayTransactions: {
    component: RazorpayTransactions,
    path: "/RazorpayTransactions",
    allowedRoles: ["Super Admin"]
  },
  TermsConditions: {
    component: TermsConditions,
    path: "/TermsConditions",
    allowedRoles: []
  },

  TermsConditionsDetail: {
    component: TermsConditionsDetail,
    path: "/TermsConditionsDetail",
    allowedRoles: []
  },
  TermsConditionsUsers: {
    component: TermsConditionsUsers,
    path: "/TermsConditionsUsers",
    allowedRoles: []
  },
  RolesPermissions: {
    component: RolesPermissions,
    path: "/RolesPermissions",
    allowedRoles: []
  },
  EmailNotifications2: {
    component: EmailNotifications2,
    path: "/EmailNotifications2",
    allowedRoles: []
  },
  VideoLibrary: {
    component: VideoLibrary,
    path: "/VideoLibrary",
    allowedRoles: []
  },
  CarouselDisplay: {
    component: CarouselDisplay,
    path: "/CarouselDisplay",
    allowedRoles: []
  },
  SocialMediaAccountLoginScreen: {
    component: SocialMediaAccountLoginScreen,
    path: "/SocialMediaAccountLoginScreen",
    allowedRoles: []
  },
  OfflineBrowsing: {
    component: OfflineBrowsing,
    path: "/OfflineBrowsing",
    allowedRoles: []
  },
  OTPInputAuth: {
    component: OTPInputAuth,
    path: "/OTPInputAuth",
    allowedRoles: []
  },
  RolesPermissions2: {
    component: RolesPermissions2,
    path: "/RolesPermissions2",
    allowedRoles: []
  },
  Videos: {
    component: Videos,
    path: "/Videos",
    allowedRoles: []
  },
  MyCash: {
    component: MyCash,
    path: "/MyCash",
    allowedRoles: ["Student"]
  },
  CourseDetails: {
    component: CourseDetails,
    path: "/Course/:courseId",
    allowedRoles: ["Student", ""]
  },
  BundleCourseDetails: {
    component: CourseDetails,
    path: "/BundleCourseDetails/:courseId",
    allowedRoles: ["Student", ""]
  },
  Couponcodegenerator: {
    component: Couponcodegenerator,
    path: "/Couponcodegenerator",
    allowedRoles: ["Super Admin"]
  },
  AddCouponcodegenerator: {
    component: AddCouponcodegenerator,
    path: "/AddCouponcodegenerator",
    allowedRoles: ["Super Admin"]
  },
  Vouchercodegenerator: {
    component: Vouchercodegenerator,
    path: "/Vouchercodegenerator",
    allowedRoles: ["Super Admin"]
  },
  AddVouchercodegenerator: {
    component: AddVouchercodegenerator,
    path: "/AddVouchercodegenerator",
    allowedRoles: ["Super Admin"]
  },
  FlatDiscount: {
    component: FlatDiscount,
    path: "/FlatDiscount",
    allowedRoles: ["Super Admin"]
  },
  AddFlatDiscount: {
    component: AddFlatDiscount,
    path: "/AddFlatDiscount",
    allowedRoles: ["Super Admin"]
  },
  Referral: {
    component: Referral,
    path: "/Referral",
    allowedRoles: ["Super Admin"]
  },
  PaymentAdmin2: {
    component: PaymentAdmin2,
    path: "/PaymentAdmin2",
    allowedRoles: []
  },
  CfRazorpayIntegration64: {
    component: CfRazorpayIntegration64,
    path: "/CfRazorpayIntegration64",
    allowedRoles: []
  },
  PushNotifications: {
    component: PushNotifications,
    path: "/PushNotifications",
    allowedRoles: ["Super Admin"]
  },
  AddPushNotifications: {
    component: AddPushNotifications,
    path: "/AddPushNotifications",
    allowedRoles: ["Super Admin"]
  },
  ForgotPassword: {
    component: ForgotPassword,
    path: "/ForgotPassword",
    allowedRoles: []
  },
  ForgotPasswordOTP: {
    component: ForgotPasswordOTP,
    path: "/ForgotPasswordOTP",
    allowedRoles: []
  },
  NewPassword: {
    component: NewPassword,
    path: "/NewPassword",
    allowedRoles: []
  },
  Referrals: {
    component: Referrals,
    path: "/Referrals",
    allowedRoles: ["Super Admin"]
  },
  AdminConsole3: {
    component: AdminConsole3,
    path: "/AdminConsole3",
    allowedRoles: []
  },
  Settings5: {
    component: Settings5,
    path: "/Settings5",
    allowedRoles: []
  },
  UserProfileBasicBlock: {
    component: UserProfileBasicBlock,
    path: "/UserProfileBasicBlock",
    allowedRoles: []
  },
  CfCaptureUuidOfDevice: {
    component: CfCaptureUuidOfDevice,
    path: "/CfCaptureUuidOfDevice",
    allowedRoles: []
  },
  BulkUploading: {
    component: BulkUploading,
    path: "/BulkUploading",
    allowedRoles: []
  },
  Categoriessubcategories: {
    component: Categoriessubcategories,
    path: "/Categoriessubcategories",
    allowedRoles: [""]
  },
  SearchCourses: {
    component: Categoriessubcategories,
    path: "/SearchCourses",
    allowedRoles: [""]
  },
  ContentManagement: {
    component: ContentManagement,
    path: "/ContentManagement",
    exact: true,
    allowedRoles: [""]
  },
  AdminCourses: {
    component: AdminCourses,
    path: "/AdminCourses",
    allowedRoles: ["Super Admin"]
  },
  AddAdminCourse: {
    component: AddAdminCourse,
    path: "/AddAdminCourse",
    allowedRoles: ["Super Admin"]
  },
  CountryCodeSelector: {
    component: CountryCodeSelector,
    path: "/CountryCodeSelector",
    allowedRoles: [""]
  },
  AdminProfile: {
    component: AdminProfile,
    path: "/AdminProfile",
    allowedRoles: ["Super Admin"]
  },
  SocialMediaAccountRegistrationScreen: {
    component: SocialMediaAccountRegistrationScreen,
    path: "/SocialMediaAccountRegistrationScreen",
    allowedRoles: [""]
  },
  ContactusWeb: {
    component: ContactusWeb,
    path: "/ContactusWeb"
  },
  Contactus: {
    component: Contactus,
    path: "/Contactus",
    allowedRoles: ["Super Admin"]
  },
  AddContactus: {
    component: AddContactus,
    path: "/AddContactus",
    allowedRoles: ["Super Admin"]
  },
  Catalogue: {
    component: Catalogue,
    path: "/Catalogue",
    allowedRoles: [""]
  },
  MyCourses: {
    component: MyCourses,
    path: "/MyCourses",
    allowedRoles: [""]
  },
  MyCoursesDetail: {
    component: MyCoursesDetail,
    path: "/MyCoursesDetail/:id/:courseId/:chapterId?/:classId?",
    allowedRoles: ["Student", ""]
  },
  CourseList: {
    component: CourseList,
    path: "/CourseList",
    allowedRoles: [""]
  },
  EducationalUserProfile: {
    component: EducationalUserProfile,
    path: "/EducationalUserProfile",
    allowedRoles: ["Super Admin"]
  },
  EmailAccountRegistration: {
    component: EmailAccountRegistration,
    path: "/EmailAccountRegistration",
    allowedRoles: [""]
  },
  Dashboard: {
    component: Dashboard,
    path: "/Dashboard",
    allowedRoles: ["Super Admin"]
  },
  Splashscreen: {
    component: Splashscreen,
    path: "/Splashscreen",
    allowedRoles: [""]
  },
  ImageThumbnail: {
    component: ImageThumbnail,
    path: "/ImageThumbnail",
    allowedRoles: [""]
  },
  ElasticSearch: {
    component: ElasticSearch,
    path: "/ElasticSearch",
    allowedRoles: [""]
  },
  EmailAccountLoginBlock: {
    component: EmailAccountLoginBlock,
    path: "/EmailAccountLoginBlock",
    allowedRoles: [""]
  },
  Wishlist: {
    component: Wishlist,
    path: "/Wishlist",
    allowedRoles: ["Student"]
  },
  LandingPage: {
    component: LandingPage,
    path: "/LandingPage",
    allowedRoles: ["Student", ""]
  },
  EmailNotifications: {
    component: EmailNotifications,
    path: "/EmailNotifications",
    allowedRoles: ["Super Admin"]
  },
  AddEmailNotifications: {
    component: AddEmailNotifications,
    path: "/AddEmailNotifications",
    allowedRoles: ["Super Admin"]
  },
  Home: {
    component: LandingPage,
    path: '/',
    exact: true,
    allowedRoles: ["Student", ""],
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage',
    allowedRoles: []
  },
  
  AdminCategory: {
    component: AdminCategory,
    path: '/admincategory',
    allowedRoles: ["Super Admin"]
  },
  AddAdminCategory: {
    component: AddAdminCategory,
    path: '/addadmincategory',
    allowedRoles: ["Super Admin"]
  },
  AdminSubCategory: {
    component: AdminSubCategory,
    path: '/adminsubcategory',
    allowedRoles: ["Super Admin"]
  },
  BundleCourse: {
    component: BundleCourse,
    path: "/BundleCourse",
    allowedRoles: ["Super Admin"]
  },
  AddBundleCourse: {
    component: AddBundleCourse,
    path: "/AddBundleCourse",
    allowedRoles: ["Super Admin"]
  },
  AddAdminSubCategory: {
    component: AddAdminSubCategory,
    path: '/addadminsubcategory',
    allowedRoles: ["Super Admin"]
  },
  
  AdminChildCategory: {
    component: AdminChildCategory,
    path: '/adminchildcategory',
    allowedRoles: ["Super Admin"]
  },
  
  AddAdminChildCategory: {
    component: AddAdminChildCategory,
    path: '/addadminchildcategory',
    allowedRoles: ["Super Admin"]
  },
  
  Testimonials: {
    component: Testimonials,
    path: '/Testimonials',
    allowedRoles: ["Super Admin"]
  },
  
  AdminInstitutes: {
    component: AdminInstitutes,
    path: '/admininstitutes',
    allowedRoles: ["Super Admin"]
  },
  
  AdminCourseClasses: {
    component: AdminCourseClasses,
    path: '/admincourseclasses',
    allowedRoles: ["Super Admin"]
  },
  
  AdminReviewsAndRatings: {
    component: AdminReviewsAndRatings,
    path: '/AdminReviewsAndRatings',
    allowedRoles: ["Super Admin"]
  },

  CourseReviewsAndRatings: {
    component: CourseReviewsAndRatings,
    path: '/CourseReviewsAndRatings',
    allowedRoles: ["Super Admin"]
  },
  
  AdminStateList: {
    component: AdminStateList,
    path: '/AdminStateList',
    allowedRoles: ["Super Admin"]
  },
  
  AddAdminDistrict: {
    component: AddAdminDistrict,
    path: '/AddAdminDistrict',
    allowedRoles: ["Super Admin"]
  },
  
  AdminDistrictList: {
    component: AdminDistrictList,
    path: '/AdminDistrictList',
    allowedRoles: ["Super Admin"]
  },
  
  AddAdminState: {
    component: AddAdminState,
    path: '/AddAdminState',
    allowedRoles: ["Super Admin"]
  },
  
  AdminPrivacyPolicy: {
    component: AdminPrivacyPolicy,
    path: '/AdminPrivacyPolicy',
    allowedRoles: ["Super Admin"]
  },

  HeaderBanner: {
    component: HeaderBanner,
    path: "/HeaderBanner",
    allowedRoles: ["Super Admin"]
  },
  WebHeaderBanner: {
    component: HeaderBanner,
    path: "/WebHeaderBanner",
    allowedRoles: ["Super Admin"]
  },
  MobileFooterBanner: {
    component: HeaderBanner,
    path: "/MobileFooterBanner",
    allowedRoles: ["Super Admin"]
  },

  AddHeaderBanner: {
    component: AddHeaderBanner,
    path: "/AddHeaderBanner",
    exact: true,
    allowedRoles: ["Super Admin"]
  },

  AddWebHeaderBanner: {
    component: AddHeaderBanner,
    path: "/AddWebHeaderBanner",
    exact: true,
    allowedRoles: ["Super Admin"]
  },

  AddMobileFooterBanner: {
    component: AddHeaderBanner,
    path: "/AddMobileFooterBanner",
    allowedRoles: ["Super Admin"]
  },
  
  AddAdminPrivacyPolicy: {
    component: AddAdminPrivacyPolicy,
    path: '/AddAdminPrivacyPolicy',
    allowedRoles: ["Super Admin"]
  },
  ShoppingCartOrders: {
    component: ShoppingCartOrders,
    path: '/ShoppingCartOrders',
    allowedRoles: ["Student"]
  },
  PurchaseHistory: {
    component: PurchaseHistory,
    path: '/Purchases'
  }
  // AlertWeb: {
  //   component: AlertBlock,
  //   path: "/AlertWeb",
  //   exact: true,
  //   modal: true
  // }

};

// const firebaseAPI2 = firebase.initializeApp({
//   apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
//   authDomain: "rnmasterapp-c11e9.firebaseapp.com",
//   databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
//   projectId: "rnmasterapp-c11e9",
//   storageBucket: "rnmasterapp-c11e9.appspot.com",
//   messagingSenderId: "649592030497",
//   appId: "1:649592030497:web:7728bee3f2baef208daa60",
//   measurementId: "G-FYBCF3Z2W3"
// });

var firebaseAPI;

if (!firebase.apps.length) {
  firebaseAPI = firebase.initializeApp({
    apiKey: "AIzaSyDlLEqEYT-kPIukB4nRMKfUUdxRCTiaLjY",
    authDomain: "chse-express-6f47f.firebaseapp.com",
    projectId: "chse-express-6f47f",
    storageBucket: "chse-express-6f47f.appspot.com",
    messagingSenderId: "786597594690",
    appId: "1:786597594690:web:5735e2056da80d71af4fdb",
    measurementId: "G-6RB56LWXS2"
  });
}

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }


  async componentDidUpdate() {
    const scrollContainer = document.getElementById('scrollContainer');
    if (scrollContainer) {
        scrollContainer.style.overflowX = 'scroll';
        setTimeout(() => {
          scrollContainer.style.overflowX = '';
      }, 100); 
    }
  }

  render() {
    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');

    return (
      <View
        style={{ height: '100vh', width: '100vw', overflowX: 'hidden',
          overflow: "auto",
          maxWidth: "1920px",
          margin: "auto"
         }}
      >
        {/* <TopNav /> */}

        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;