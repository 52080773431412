Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "ContentManagement";
exports.labelBodyText = "ContentManagement Body";
exports.httpGetType = "GET";
exports.httpPostType = "POST";
exports.httpPutType = "PUT";
exports.httpDeleteType = "DELETE";
exports.courseChaptersApiContentType = "application/json";
exports.getCourseChaptersAPiEndPoint = "bx_block_profile/course_chapters/";
exports.deleteCourseApiEndPoint= "bx_block_profile/courses/"
exports.getCourseListAPIEndPoint = "bx_block_profile/courses/course_dropdown"
exports.courseClassesApiContentType = "application/json";
exports.getAllCourseClassesAPIEndPoint = "bx_block_profile/course_class/"
exports.getCoursesByName = "bx_block_profile/courses/search_by_name";
exports.getCoursesList = "bx_block_profile/courses/list_courses";
exports.btnExampleTitle = "CLICK ME";
exports.CityAPiEndPoint = `bx_block_profile/cities/show_cities/`
exports.statusApiEndPoint="bx_block_profile/courses/update_status/";
exports.trendingUpdateAPIEndPoint ="bx_block_profile/courses/update_trending/"
exports.courseCategoryAPIListEndPoint = "bx_block_course/course_categories/list_categories_for_sub_category";
exports.getInstructorsListAPIEndpoint = "account_block/list_instructors"
exports.getLanguagesListAPIEndpoint = "bx_block_language_options/content_languages/list_languages"
exports.getInstitutesListAPIEndpoint = "bx_block_profile/institutes/list_institutes"
exports.getSubCategoriesAPIEndpoint = "bx_block_course/course_sub_category/get_sub_category_based_category"
exports.getChildCategoriesAPIEndpoint = "bx_block_course/get_child_category_course"
exports.ReviewsAndRatingsApiContentType = "application/json";
exports.getAllReviewsAndRatingsAPIEndPoint ="bx_block_profile/reviews/admin_list_review";
exports.getAllStudentListAPIEndPoint ="account_block/list_students";
exports.postReviewsAndRatingsAPIEndPoint ="bx_block_profile/reviews/admin_create_review";
exports.deleteReviewsAndRatingsAPIEndPoint ="bx_block_profile/reviews/admin_destroy_review";
exports.showReviewsAndRatingsAPIEndPoint ="bx_block_profile/reviews";
exports.updateReviewsAndRatingsAPIEndPoint ="bx_block_profile/reviews/admin_update_review";
exports.tokenExpiredMessage = "Token has Expired";
exports.courseClassNotFoundMessage = "Course class record not found.";
exports.recordNotFoundMessage = "Record not found.";
exports.maxFileSize = 2 * 1024 * 1024;
exports.browserText = "Browse";
exports.details = "Details";
exports.courseValidityMsg = "This course validity has not ended and students are live.";
exports.deleteConfirmationMsg = "Do you still want to delete it?";
exports.editCourseClasses = "Edit Course Classes";
exports.addCourseClasses = "Add Course Classes";
exports.uplaodVideoText = "Upload  Video...";
exports.editMultiVideoUploadMsg = "During the Edit Course Class process, only a single video can be uploaded."
exports.sessionExpireMsg = "Session is expired. Please login again.";

exports.addEditReviewApiEndPoint = "bx_block_profile/testimonials/create_testimonials";
exports.testimonialsListApiEndpoint = "bx_block_profile/testimonials/list_testimonials";
exports.deleteCourseReviewAPIEndPoint = "bx_block_profile/testimonials";
exports.updateCourseReviewAPIEndPoint = '/bx_block_profile/testimonials/'
exports.courseEditPath = "AddAdminCourse";
exports.courseChapterPath = "ContentManagement";
exports.courseClassesPath = "AdminCourseClasses";
exports.courseRatingsPath = "CourseReviewsAndRatings";

exports.addReviewsAndRatingsText = "Add Ratings & Review";
exports.courseFieldText = "Course*";
exports.commentFieldText = "Comment*";
exports.cancelBtnText = "Cancel";
exports.resetBtnText = "Reset";
exports.updateBtnText = "Update";
exports.addReviewsAndRatingsBtnText = "Add Ratings & Review";
exports.recordNotFound = 'Record not found'
// Customizable Area End